export const FooterThirdRoutes = [
  {
    title: "About Us",
    href: "./about-us",
    icon: <i class="fa-solid fa-chevron-right"></i>,
  },
  {
    title: "Gallery",
    href: "./gallery",
    icon: <i class="fa-solid fa-chevron-right"></i>,
  },
  {
    title: "Services",
    href: "./services",
    icon: <i class="fa-solid fa-chevron-right"></i>,
  },
  {
    title: "Blogs",
    href: "./blogs",
    icon: <i class="fa-solid fa-chevron-right"></i>,
  },
  {
    title: "Contact",
    href: "./contact-us",
    icon: <i class="fa-solid fa-chevron-right"></i>,
  },
];
