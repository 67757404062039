import React from "react";
import HeroImage from "../../../assets/commonbanner.webp";

const TeamDetails1 = () => {
  const containerStyle = {
    background: "url(" + HeroImage + ") center/cover",
    backgroundColor: "#1C242970",
    height: "475px",
  };
  return (
    <div>
      <div style={containerStyle} className="flex items-center justify-center">
        <h2 className="text-white text-[60px]">Team Name 1</h2>
      </div>
      <main className="mt-36">
        {/* <!-- team details area start --> */}
        <div class="team-details-area pb-120">
          <div class="container">
            <div class="team-details-author-main-box-1-1 bg-white">
              <div class="row">
                <div class="col-xl-6 col-lg-6">
                  <div class="team__details-img w-img md:mr-70">
                    <img
                      src={require("../../../assets/img/team/team-5.jpg")}
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                  <div class="team__details-content pt-105">
                    <span class="wow fadeInUp" data-wow-delay=".4s">
                      UI/UX Designer
                    </span>
                    <h3 class="wow fadeInUp" data-wow-delay=".6s">
                      Parsley Montana
                    </h3>
                    <p class="wow fadeInUp" data-wow-delay=".8s">
                      So I said on your bike mate easy peasy dropped a clanger
                      blow porkies is fantastic show off show.!
                    </p>
                    <div class="team__details-contact mb-45">
                      <ul>
                        <li class="wow fadeInUp" data-wow-delay="1s">
                          <div class="icon theme-color ">
                            <i class="fal fa-envelope"></i>
                          </div>
                          <div class="text theme-color ">
                            <span>
                              <a
                                className="no-underline"
                                href="/cdn-cgi/l/email-protection#c0b3b5b0b0afb2b480baa9a2a2a5b2eea3afad"
                              >
                                <span
                                  className="__cf_email__ text-[#DFB68D]"
                                  data-cfemail="85f6f0f5f5eaf7f1c5ffece7e7e0f7abe6eae8"
                                >
                                  support@gmail.com
                                </span>
                              </a>
                            </span>
                          </div>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay="1s">
                          <div class="icon theme-color">
                            <i class="fas fa-phone-alt"></i>
                          </div>
                          <div class="text theme-color">
                            <span>
                              <a
                                className="no-underline pl-3 text-[#DFB68D]"
                                href="#"
                              >
                                {" "}
                                +910000000000
                              </a>
                            </span>
                          </div>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay="1s">
                          <div class="icon">
                            <i class="fal fa-map-marker-alt"></i>
                          </div>
                          <div class="text">
                            <span>
                              306 B wing, Kotia Nirman, New link road, Near Fun
                              republic, Andheri west, Mumbai – 53.
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="team__details-social theme-social wow fadeInUp"
                      data-wow-delay="1s"
                    >
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fab fa-facebook-f text-[#777777] hover:text-white"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fab fa-twitter text-[#777777] hover:text-white"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fab fa-vimeo-v text-[#777777] hover:text-white"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10">
                <div class="team__details-info mt-60">
                  <h4 class="wow fadeInUp" data-wow-delay=".4s">
                    {" "}
                    Information
                  </h4>
                  <p class="wow fadeInUp" data-wow-delay=".6s">
                    jolly good codswallop what a plonker he nicked it
                    bog-standard porkies gosh the full monty, wind up at public
                    school hanky panky cheeky bugger Richard do one some dodgy
                    chav bite your arm off. Argy-bargy excuse my French brown
                    bread up the duff bleeder fanny around spend a penny barmy
                    bonnet, bubble and squeak brolly bugger no biggie smashing
                    get stuffed mate old lurgy, cup of tea nice one mufty that I
                    knackered some dodgy chav. Say vagabond morish crikey excuse
                    my French bonnet William blatant spend a penny, knackered
                    bite your arm off what a plonker blimey smashing a blinding
                    shot pardon me grub, wind up cracking goal Jeffrey hanky
                    panky are you taking the piss such a fibber hunky-dory.
                  </p>{" "}
                  <p class="wow fadeInUp" data-wow-delay=".8s">
                    So I said on your bike mate easy peasy dropped a clanger
                    blow off porkies is fantastic show off show off pick your
                    nose and blow off, faff about bubble and squeak bugger all
                    mate happy days hotpot don't get shirty with me jolly good
                    gormless barmy.
                  </p>
                  <a
                    href="portfolio.html"
                    data-wow-delay=".9s"
                    class="lv-banner-link-3 wow fadeInUp no-underline"
                  >
                    Appionment
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- team details area end --> */}
      </main>
    </div>
  );
};

export default TeamDetails1;
