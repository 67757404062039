import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage1 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white lg:text-[60px] text-center z-0">
          GUIDE TO A SUSTAINABLE WEDDING
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="">
                      <img
                        className="w-full"
                        src={require("../../../assets/img/blog/blog-1.png")}
                        alt=""
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove March 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          GUIDE TO A SUSTAINABLE WEDDING
                        </h4>

                        <p class="mb-25">
                          {" "}
                          Weddings are meant to be the greatest day of
                          everyone’s life. While it’s tempting to think of a
                          wedding as a one-day event, your D-day could have a
                          great impact on the environment. With leftover flowers
                          tossed in the garbage, excess food thrown away and
                          dumped paper goods, it often turns into the most
                          wasteful event. We do not ask you to forgo the
                          gorgeous details or skip the party. Instead, we mean
                          to say that you should host a wedding that is as
                          eco-friendly as possible. A green wedding is a
                          sustainable or eco-friendly wedding in which couples
                          try to reduce a lot of the impacts of the wedding on
                          the environment. If you’re interested, check out the 7
                          ways mentioned here on how to plan a wedding that’s
                          sustainable.
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}
                        <h4 class="lv-blog-title-1-8">
                          7 Ways To Plan A Sustainable Wedding
                        </h4>
                        <h5 class="">
                          1. Pick A Venue That Prioritises Sustainability
                        </h5>
                        <p class="mb-30">
                          Did you know that one of the easiest ways to have a
                          wedding sustainably is to pick the right venue?
                          Picking a ceremony as well as a reception venue that
                          takes sustainability seriously will lower your
                          wedding’s carbon footprint. When hunting for a venue
                          that prioritises sustainability, online resources can
                          be of great help. Opt for event spaces and hotels with
                          Energy Star ratings and Leadership in Energy &
                          Environmental Design (LEED) certifications. With so
                          many locales going green, you won’t have to go
                          sustainable at the cost of style. Or, perhaps, you can
                          celebrate your sacred union outdoors. A picturesque
                          vineyard, rolling hills or a lust forest- there are
                          numerous stunning locations where you can tie the
                          knot. By hosting your wedding outdoors, you’ll also
                          reduce a lot of the energy costs, while the natural
                          light will produce stunning pictures.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.png")}
                            alt=""
                            className="w-[600px] m-auto "
                          />
                        </p>
                        <h5 class="">2. Keep The Guest List Small</h5>
                        <p class="mb-30">
                          Remember, your guest list has a significant impact in
                          deciding the amount of invites, venue, menu, wedding
                          favours, and other aspects of your wedding. Inviting a
                          lot of guests to the wedding will produce more waste,
                          so if you want to make your wedding more eco-friendly,
                          keep your guest list small. Limiting it to your family
                          and well-wishers is the only way to reduce carbon
                          emission at your wedding. Plus, there’s no point in
                          meeting strangers who don’t really care about your big
                          day, right?
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.2.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">3. Rethink Your Wedding Invitation</h5>
                        <p class="mb-30">
                          Instead of sending run-of-the-mill cards, go green
                          with the invitation. Opt for cards printed on recycled
                          paper or look for sustainable alternatives to paper
                          such as wood, leather, and upcycled fabrics. That’s
                          because they do not use as much water or energy as
                          paper. For the most earth-friendly stationery, opt for
                          seed paper for printing menus, programs and
                          invitations. Moreover, a few vendors plant a tree for
                          every order they receive. And quite a few vendors
                          offer a whole line of items on plantable papers, so
                          going for them will be a win-win situation! To make
                          your wedding sustainable, say no to invites with too
                          many decorations. No matter how fancy wedding invites
                          are, people ultimately throw them. In such instances,
                          e-invites are the best way forward. You will also cut
                          down on printing and delivery costs, using the money
                          for planning more fun but sustainable activities.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.3.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">4. Opt For Reusable Decor</h5>
                        <p class="mb-30">
                          Irrespective of how scenic your wedding venue is,
                          you’ll need some decor for the backdrop of your
                          reception and ceremony. Fresh flowers, candles,
                          picture frames, mirrors, centrepieces and signs are
                          fantastic decor ideas. You can also use lanterns and
                          votives to set the backdrop of your venue, or later
                          you can use these items for your home decor.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.4.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">5. Eco-Friendly Wedding Favours</h5>
                        <p class="mb-30">
                          Wedding favours could either be a great souvenir or a
                          waste of money. Your sustainable wedding won’t lower
                          your carbon footprint if you gift single-use items
                          packed in fancy wrappers. Instead of giving items that
                          would probably lie in the corner of a closet, why not
                          give something that your guests will use or enjoy?
                          People often prefer something sweet like a doughnut,
                          savoury midnight snack, or burger engraved with your
                          wedding date. Giving something edible is the key to
                          reducing the carbon footprint on wedding favours.
                          Support the regional economy by buying snacks from
                          local merchants. Another idea? Many couples have
                          completely forgone the idea of wedding favours to
                          donate to a charity in the name of their guests. If
                          you intend to do the same, declare this charitable act
                          on the menu card of your green wedding. Or put up an
                          elegant sign to announce the good deed.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.5.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          6. Give Your Beauty Drawer An Ethical Makeover
                        </h5>
                        <p class="mb-30">
                          When it comes to an eco-friendly wedding, your beauty
                          drawer is as important as the decor and the venue. In
                          this regard, what you can do is try getting your hands
                          on locally sourced products or those that are
                          cruelty-free. When you have no idea about eco-friendly
                          beauty products, asking your makeup artist and hair
                          stylists will be the best bet.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.6.jpg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          7. Choose An Eco-Friendly Wedding Dress
                        </h5>
                        <p class="mb-30">
                          If you think choosing eco-friendly wedding attire
                          means sacrificing style, we urge you to think again!
                          Though wedding dresses are designed as “fast fashion,”
                          you aren’t out of luck. With so many designers out
                          there, finding one that specialises in ethical
                          practices isn’t impossible. Even pre-loved items are
                          eco-friendly alternatives to expensive gowns sold by
                          designers. You can also grab your mother’s or
                          grandmother’s wedding dress, upcycle it and re-use it
                          on your D-day to look one-of-a-kind.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage1.7.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h4 class="lv-blog-title-1-8">
                          Strive For Sustainability, Not Perfection!
                        </h4>
                        <p class="mb-30">
                          A sustainable and eco-friendly wedding requires
                          careful preparation at every stage only then will you
                          be able to minimise its adverse effects on the
                          environment. From the guest list to food, choose
                          everything with utmost care. But things don’t end
                          there; making sure your honeymoon is ethical is
                          equally important. Instead of a long-haul trip,
                          consider a local getaway or a backpacking trip nearby.
                          Even if you and your fiance cannot completely give up
                          on the idea of a lavish marriage, a bit of effort can
                          go a long way. Through these subtle ways, you can make
                          a world of difference.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage1;
