import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Darshini&Servesh/Darshini&Servesh.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const DarshiniServesh = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    portfolio1: require("../../assets/img/portfolio/Darshini&Servesh/1.webp"),
    portfolio2: require("../../assets/img/portfolio/Darshini&Servesh/2.webp"),
    portfolio3: require("../../assets/img/portfolio/Darshini&Servesh/3.webp"),
    portfolio4: require("../../assets/img/portfolio/Darshini&Servesh/4.webp"),
    portfolio5: require("../../assets/img/portfolio/Darshini&Servesh/5.webp"),
    portfolio6: require("../../assets/img/portfolio/Darshini&Servesh/6.webp"),
    portfolio7: require("../../assets/img/portfolio/Darshini&Servesh/7.webp"),
    portfolio8: require("../../assets/img/portfolio/Darshini&Servesh/8.webp"),
    portfolio9: require("../../assets/img/portfolio/Darshini&Servesh/9.webp"),
    portfolio10: require("../../assets/img/portfolio/Darshini&Servesh/10.webp"),
    portfolio11: require("../../assets/img/portfolio/Darshini&Servesh/11.webp"),
    portfolio12: require("../../assets/img/portfolio/Darshini&Servesh/12.webp"),
    portfolio13: require("../../assets/img/portfolio/Darshini&Servesh/13.webp"),
    portfolio14: require("../../assets/img/portfolio/Darshini&Servesh/14.webp"),
    portfolio15: require("../../assets/img/portfolio/Darshini&Servesh/15.webp"),
    portfolio16: require("../../assets/img/portfolio/Darshini&Servesh/16.webp"),
    portfolio17: require("../../assets/img/portfolio/Darshini&Servesh/17.webp"),
    portfolio18: require("../../assets/img/portfolio/Darshini&Servesh/18.webp"),
    portfolio19: require("../../assets/img/portfolio/Darshini&Servesh/19.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative -z-[900] "
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-center text-[60px] z-0">
          {" "}
          Pondharshini & Servesh
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row justify-center gap-10 lg:px-10 px-2 py-10">
        <div class="lg:w-[500px]">
          <div class="lv-portfolio-details-top-wrap-1-4 mb-35">
            <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
              Pondharshini & Servesh
            </h4>

            <span class="lv-portfolio-date-1-4">
              January 2024, Escover & Kinos Cottage, Mumbai
            </span>
          </div>
          <div class="lv-portfolio-details-content-1-4 mb-30">
            <h2>Love United: Sarvesh and Pondharshini's PopXO Wedding</h2>
            <p class="mb-25">
              In the heart of San Francisco, Sarvesh, a Marathi, and
              Pondharshini, a Tamilian, met at university, where their worlds
              collided in a serendipitous encounter. Their love blossomed amidst
              lectures and laughter, weaving a tapestry of cultural fusion.
            </p>
            <p>
              Their wedding here in Mumbai was a typical Marathi-style three-day
              spectacle. Festivities began with a simple sangeet, followed by a
              vibrant Mehendi cocktail party in the evening at Escobar, where
              Marathi and Tamilian melodies intertwined harmoniously. The
              exuberance of a Haldi ceremony in Khar Danda, near Sarvesh's
              family home, where turmeric adorned their skin, symbolizing
              blessings and purity amidst the rustic charm of tradition.
            </p>
            <p>
              On the third day, beneath the azure sky at Kino’s Cottage, they
              exchanged vows in a sacred ceremony, before revelling in a grand
              formal reception, where opulence and elegance merged in a
              celebration of love. Showcased a fusion of Marathi and Tamilian
              cuisines and traditions, uniting their families and friends in a
              joyous celebration.
            </p>
            <p>
              As they danced under the stars, Sarvesh and Pondharshini
              epitomized the beauty of diversity, their union a testament to the
              power of love to transcend cultural boundaries, inspiring all who
              witnessed their journey.
            </p>
          </div>
        </div>

        <div className="lg:w-[600px]">
          <div className="flex gap-3 flex-col lg:flex-row">
            <div className="py-3">
              <img
                className=""
                src={require("../../assets/img/portfolio/Darshini&Servesh/13.webp")}
              />
            </div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio1"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio2"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio3"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio4"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio5"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio6"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio7"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio8"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-no-repeat  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio9"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio10"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio11"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio12"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio13"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio14"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio15"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio16"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio17"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio18"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio19"]})`,
              }}
            ></div>
          </div>

          {/* Modal for displaying the clicked image */}
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Modal"
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DarshiniServesh;
