import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Amruta&Aniket/Amruta&Aniketbanner.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const PortfolioDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    weiding: require("../../assets/img/bg/weiding-bg.webp"),
    gallery1: require("../../assets/img/gallery/gallery-1.1.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#000000af] z-0"></div>

        <h2 className="text-white text-center text-[60px] z-0">
          Taj Land Celebration
        </h2>
      </div>

      <div class="portfolio-details-area pb-120 pt-120">
        <div class="container">
          <div class="row pb-105">
            <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-12 mb-50 mb-lg-0">
              <div class="lv-portfolio-details-left-1-4">
                <div class="lv-portfolio-details-top-wrap-1-4 mb-35">
                  <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
                    Taj Land
                    <br />
                    Celebration
                  </h4>
                  <h5 class="lv-portfolio-member-name-1-4 text-[#000066]">
                    Ajay & Priyanka{" "}
                  </h5>
                  <span class="lv-portfolio-date-1-4">
                    Wednesday, 13 March 2024
                  </span>
                </div>
                <div class="lv-portfolio-details-content-1-4 mb-30">
                  <p class="mb-25">
                    You’re so excited that you aren’t sure where to begin, who
                    to hire, or what to do next. You wish you had a trusted, who
                    felt more like a friend that could take the guesswork out of
                    all of these decisions so you could relax
                  </p>
                  <p>
                    You’re so excited that you aren’t sure where to begin, who
                    to hire, or what to do next. You wish you had a trusted, who
                    felt more like a friend that could take the guesswork out of
                    all of these decisions so you could relax
                  </p>
                </div>
                <div class="lv-portfolio-meta-list-1-4">
                  <ul>
                    <li>
                      <span>Category :</span> Wedding
                    </li>
                    <li>
                      <span>Location :</span> Taj Land, Bandra Mumbai
                    </li>
                    <li>
                      <span>Date :</span> Wednesday, 13 March 2024
                    </li>
                    <li>
                      <span>Tags :</span> Wedding, Taj Land, Mumbai Wedding
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-12">
              <div className="gallery-grids">
                <div className="row g-15">
                  {/* Displaying the first large image */}
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 grid-items">
                    <div
                      className="lv-p-details-grid-item-1-4-1 mb-15 bg-default"
                      style={{ backgroundImage: `url(${images["weiding"]})` }}
                    ></div>
                  </div>

                  {/* Mapping other images, assuming similar behavior for simplicity */}
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 grid-items"
                    >
                      <div
                        className="lv-p-details-grid-item-1-4-1 mb-15 bg-default"
                        style={{
                          backgroundImage: `url(${images["weiding"]})`,
                        }}
                      ></div>
                    </div>
                  ))}

                  {/* Modal for displaying the clicked image */}
                  {isModalOpen && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000,
                      }}
                      onClick={closeModal}
                    >
                      <img
                        src={selectedImage}
                        alt="Modal"
                        style={{ maxWidth: "90%", maxHeight: "90%" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetails;
