import React from "react";
import HeroImage from "../../assets/img/slider/slider-1.webp";

// Sample data for team members
const teamMembers = [
  {
    id: 1,
    name: "Aman Singh",
    role: "Photographer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
  {
    id: 2,
    name: "Rahul Gupta",
    role: "Designer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
  {
    id: 3,
    name: "Shimla Shukla",
    role: "Designer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
  {
    id: 4,
    name: "Priyanka Singh",
    role: "Designer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
  {
    id: 5,
    name: "Shubham Singh",
    role: "Designer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
  {
    id: 6,
    name: "Akash Singh",
    role: "Designer",
    imageUrl: `${require("../../assets/img/team/team-5.jpg")}`,
    profileUrl: "/team-details-name-1",
    socialLinks: { facebook: "#", twitter: "#", linkedin: "#" },
  },
];

const Team = () => {
  const containerStyle = {
    background: "url(" + HeroImage + ") center/cover",
    backgroundColor: "#1C242970",
    height: "475px",
  };

  return (
    <div>
      <div style={containerStyle} className="flex items-center justify-center">
        <h2 className="text-white text-[60px]">Team</h2>
      </div>

      <main className="mt-36">
        {/* team area start */}
        <div className="team-area pb-75">
          <div className="container">
            <div className="row">
              {teamMembers.map((member) => (
                <div
                  key={member.id}
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-6"
                >
                  <div className="lv-photography-member-social-share lv-photography-member-social-share-1-3 mb-20">
                    <div className="fix">
                      <div
                        className="lv-photography-img-1-2 bg-default lv-photography-img"
                        style={{ backgroundImage: `url(${member.imageUrl})` }}
                      ></div>
                    </div>
                    <div className="lv-photography-member-content text-center">
                      <a
                        href={member.profileUrl}
                        className="lv-photography-member-designation-link no-underline text-[#777777]"
                      >
                        {member.role}
                      </a>
                      <h5 className="lv-photography-member-name">
                        <a
                          className="no-underline text-black hover:text-[#DFB68D]"
                          href={member.profileUrl}
                        >
                          {member.name}
                        </a>
                      </h5>
                      <div className="lv-photography-member-social-links">
                        <a
                          className="no-underline text-[#777777]"
                          href={member.socialLinks.facebook}
                        >
                          FB
                        </a>
                        <a
                          className="no-underline text-[#777777]"
                          href={member.socialLinks.twitter}
                        >
                          TW
                        </a>
                        <a
                          className="no-underline text-[#777777]"
                          href={member.socialLinks.linkedin}
                        >
                          IN
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* team area end */}
      </main>
    </div>
  );
};

export default Team;
