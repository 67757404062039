import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";
import PortfolioDetails from "./page/PortfolioDetailsPage/PortfolioDetails";
import Insights from "./page/Insights/Insights";
import DetailsPage1 from "./page/Insights/InsightsDetailsPage/DetailsPage1";
import DetailsPage2 from "./page/Insights/InsightsDetailsPage/DetailsPage2";
import DetailsPage3 from "./page/Insights/InsightsDetailsPage/DetailsPage3";
import DetailsPage4 from "./page/Insights/InsightsDetailsPage/DetailsPage4";
import Team from "./page/Team/Team";
import TeamDetails1 from "./page/Team/TeamDetailsPage/TeamDetails1";
import Gallery from "./page/Gallery/Gallery";
import PopUpForm from "./components/PopUpForm";
import AnanyaRohan from "./page/PortfolioDetailsPage/AnanyaRohan";
import AmrutaAniket from "./page/PortfolioDetailsPage/AmrutaAniket";
import DarshiniServesh from "./page/PortfolioDetailsPage/DarshiniServesh";
import ShamikaManish from "./page/PortfolioDetailsPage/ShamikaManish";
import RahulNamrata from "./page/PortfolioDetailsPage/RahulNamrata";
import DetailsPage5 from "./page/Insights/InsightsDetailsPage/DetailsPage5";
import DetailsPage6 from "./page/Insights/InsightsDetailsPage/DetailsPage6";
import DetailsPage7 from "./page/Insights/InsightsDetailsPage/DetailsPage7";

// Lazy loading components and pages
const Header = React.lazy(() => import("./components/Header/Header"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const Home = React.lazy(() => import("./page/Home/Home"));
const AboutUs = React.lazy(() => import("./page/About/AboutUs"));
const Services = React.lazy(() => import("./page/Services/Services"));
const ContactUs = React.lazy(() => import("./page/Contact Us/ContactUs"));
const Portfolio = React.lazy(() => import("./page/Portfolio/Portfolio"));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio-details" element={<PortfolioDetails />} />
          <Route path="/ananya-rohan" element={<AnanyaRohan />} />
          <Route path="/amruta-aniket" element={<AmrutaAniket />} />
          <Route path="/darshini-servesh" element={<DarshiniServesh />} />
          <Route path="/shamika-manish" element={<ShamikaManish />} />
          <Route path="/rahul-namrata" element={<RahulNamrata />} />
          <Route path="/blogs" element={<Insights />} />
          <Route path="/team" element={<Team />} />
          <Route path="/gallery" element={<Gallery />} />

          <Route
            path="/guide-to-a-sustainable-wedding"
            element={<DetailsPage1 />}
          />
          <Route
            path="/how-to-play-your-wedding-sangeet?"
            element={<DetailsPage2 />}
          />
          <Route
            path="/destination-weddings-dos-and-donts"
            element={<DetailsPage3 />}
          />
          <Route
            path="/reasons-you-should-hire-a-wedding-planner"
            element={<DetailsPage4 />}
          />
          <Route
            path="/monsoon-wedding-destinations"
            element={<DetailsPage5 />}
          />
          <Route
            path="/dos-donts-for-a-monsoon-wedding"
            element={<DetailsPage6 />}
          />
          <Route
            path="/colour-trends-in-the-wedding-industry-2024"
            element={<DetailsPage7 />}
          />
          <Route path="/team-details-name-1" element={<TeamDetails1 />} />
          <Route path="/popup" element={<PopUpForm />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
