import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Ananya&Rohan/Ananya&Rohan.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const AnanyaRohan = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    portfolio1: require("../../assets/img/portfolio/Ananya&Rohan/1.webp"),
    portfolio2: require("../../assets/img/portfolio/Ananya&Rohan/2.webp"),
    portfolio3: require("../../assets/img/portfolio/Ananya&Rohan/3.webp"),
    portfolio4: require("../../assets/img/portfolio/Ananya&Rohan/4.webp"),
    portfolio5: require("../../assets/img/portfolio/Ananya&Rohan/5.webp"),
    portfolio6: require("../../assets/img/portfolio/Ananya&Rohan/6.webp"),
    portfolio7: require("../../assets/img/portfolio/Ananya&Rohan/7.webp"),
    portfolio8: require("../../assets/img/portfolio/Ananya&Rohan/8.webp"),
    portfolio9: require("../../assets/img/portfolio/Ananya&Rohan/9.webp"),
    portfolio10: require("../../assets/img/portfolio/Ananya&Rohan/10.webp"),
    portfolio11: require("../../assets/img/portfolio/Ananya&Rohan/11.webp"),
    portfolio12: require("../../assets/img/portfolio/Ananya&Rohan/12.webp"),
    portfolio13: require("../../assets/img/portfolio/Ananya&Rohan/13.webp"),
    portfolio14: require("../../assets/img/portfolio/Ananya&Rohan/14.webp"),
    portfolio15: require("../../assets/img/portfolio/Ananya&Rohan/15.webp"),
    portfolio16: require("../../assets/img/portfolio/Ananya&Rohan/16.webp"),
    portfolio17: require("../../assets/img/portfolio/Ananya&Rohan/17.webp"),
    portfolio18: require("../../assets/img/portfolio/Ananya&Rohan/18.webp"),
    portfolio19: require("../../assets/img/portfolio/Ananya&Rohan/19.webp"),
    portfolio20: require("../../assets/img/portfolio/Ananya&Rohan/20.webp"),
    portfolio21: require("../../assets/img/portfolio/Ananya&Rohan/21.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>

        <h2 className="text-white text-center text-[60px] z-0">
          Ananya & Rohan
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row justify-center gap-10 lg:px-10 px-2 py-10">
        <div class="lg:w-[500px]">
          <div class="lv-portfolio-details-top-wrap-1-4 mb-35">
            <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
              Ananya & Rohan
            </h4>

            <span class="lv-portfolio-date-1-4">
              December 2022, St Regis Hotel, Mumbai
            </span>
          </div>
          <div class="lv-portfolio-details-content-1-4 mb-30">
            <h2>Cross-cultural love at its finest</h2>
            <p class="mb-25">
              Ananya and Rohan's wedding was a radiant fusion of two vibrant
              cultures, blending Bengali and Christian traditions seamlessly.
              Ananya, resplendent in her traditional Bengali attire, exuded
              grace and elegance as she walked down the aisle towards Rohan, the
              epitome of charm in his dapper Christian attire.
            </p>
            <p>
              The ceremony was a harmonious blend of rituals from both cultures,
              symbolizing the union of two souls and two families. The air was
              filled with the melodious sounds of music and laughter, as guests
              from diverse backgrounds came together to celebrate love in its
              purest form.
            </p>
            <p>
              From the colourful decorations to the delectable spread of Bengali
              and Christian delicacies, every aspect of the wedding reflected
              the richness of their shared heritage. The dance floor came alive
              with energetic performances, showcasing the joy and excitement of
              the occasion.
            </p>
            <p>
              As the festivities continued late into the night, it was evident
              that Ananya and Rohan's love knew no boundaries, transcending
              cultural differences and bringing together people from all walks
              of life in a celebration of unity and togetherness.
            </p>
          </div>
        </div>

        <div className="lg:w-[600px]">
          {/* Mapping other images, assuming similar behavior for simplicity */}

          <div className="py-3">
            <img
              className=""
              src={require("../../assets/img/portfolio/Ananya&Rohan/12.webp")}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio1"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio2"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio3"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio4"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio5"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio6"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio7"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio8"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-no-repeat  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio9"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio10"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio11"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio12"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio13"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio14"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio15"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio16"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio17"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio18"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio19"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio20"]})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3 py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio21"]})`,
              }}
            ></div>
            {/* <div
                      className=" lg:w-[400px] h-[300px] bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${images["portfolio21"]})`,
                      }}
                    ></div> */}
          </div>

          {/* Modal for displaying the clicked image */}
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Modal"
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnanyaRohan;
