import LightGallery from "lightgallery/react";
import React, { useEffect, useRef } from "react";
import HeroImage from "../../assets/img/slider/gallerybanner.jpg";
import "./Gallery.css";
/// Import LightGallery styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";

// Import plugins
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";

const images = [
  {
    src: require("./TrunkinloveImg/4.png"),
  },
  {
    src: require("./TrunkinloveImg/5.png"),
  },
  {
    src: require("./TrunkinloveImg/6.png"),
  },
  {
    src: require("./TrunkinloveImg/7.png"),
  },
  {
    src: require("./TrunkinloveImg/8.png"),
  },
  {
    src: require("./TrunkinloveImg/9.png"),
  },
  {
    src: require("./TrunkinloveImg/10.png"),
  },
  {
    src: require("./TrunkinloveImg/11.png"),
  },
  {
    src: require("./TrunkinloveImg/12.png"),
  },
  {
    src: require("./TrunkinloveImg/13.png"),
  },
  {
    src: require("./TrunkinloveImg/14.png"),
  },
  {
    src: require("./TrunkinloveImg/15.png"),
  },
  {
    src: require("./TrunkinloveImg/16.png"),
  },
  {
    src: require("./TrunkinloveImg/17.png"),
  },
  {
    src: require("./TrunkinloveImg/18.png"),
  },
  {
    src: require("./TrunkinloveImg/19.png"),
  },
  {
    src: require("./TrunkinloveImg/20.png"),
  },
  {
    src: require("./TrunkinloveImg/21.png"),
  },
  {
    src: require("./TrunkinloveImg/22.png"),
  },
  {
    src: require("./TrunkinloveImg/23.png"),
  },
  {
    src: require("./TrunkinloveImg/24.png"),
  },
  {
    src: require("./TrunkinloveImg/25.png"),
  },
  {
    src: require("./TrunkinloveImg/26.png"),
  },
  {
    src: require("./TrunkinloveImg/27.png"),
  },
  {
    src: require("./TrunkinloveImg/28.png"),
  },
  {
    src: require("./TrunkinloveImg/29.png"),
  },
  {
    src: require("./TrunkinloveImg/31.png"),
  },
  {
    src: require("./TrunkinloveImg/32.png"),
  },
  {
    src: require("./TrunkinloveImg/33.png"),
  },
  {
    src: require("./TrunkinloveImg/34.png"),
  },
  {
    src: require("./TrunkinloveImg/1.png"),
  },
  {
    src: require("./TrunkinloveImg/2.png"),
  },
  {
    src: require("./TrunkinloveImg/3.png"),
  },
];

const containerStyle = {
  background: `url(${HeroImage}) center/cover no-repeat`,
  backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
  height: "60vh",
};

const Gallery = () => {
  const lgRef = useRef();

  useEffect(() => {
    if (lgRef.current) {
      console.log("LightGallery component has been mounted:", lgRef.current);
    } else {
      console.log("LightGallery ref is not attached");
    }
  }, []);
  return (
    <div className="gallery-container ">
      <div
        style={containerStyle}
        className="flex items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[40px] z-0">Gallery</h2>
      </div>
      <div className="gallery-container -z-[900]">
        <LightGallery ref={lgRef} speed={500} plugins={[lgThumbnail, lgZoom]}>
          {images.map((image, index) => (
            <a key={index} href={image.src} className="hoverable-container ">
              <img
                src={image.src}
                alt={image.alt}
                style={{ width: "100%", display: "block" }}
              />
              <div className="text-[20px]  hover-content tracking-[6px]">
                Click Here
              </div>
            </a>
          ))}
        </LightGallery>
      </div>
    </div>
  );
};
export default Gallery;
