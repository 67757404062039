import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage5 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          MONSOON WEDDING DESTINATIONS
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/Monsoon/banner.png")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove April 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          MONSOON WEDDING DESTINATIONS
                        </h4>

                        <p class="mb-25">
                          {" "}
                          For many people, monsoons are the best time of the
                          year as the weather becomes cooler after the scorching
                          summer heat. The rains clear away the dust, making
                          everything feel fresh and new. It is also why many
                          people choose to get married during this season,
                          especially if a destination wedding is on the cards.
                          There are several amazing destinations to choose from,
                          each offering beautiful vistas, fantastic cuisine, and
                          more. But with such weddings that are to be held
                          during the monsoon, finding the perfect location can
                          be highly confusing. Each location appears to be best
                          in some aspect or the other. Fortunately, we’ve
                          already made a list of the top options worth
                          considering to make your job easier, so check them out
                          below!
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}
                        <h4 class="lv-blog-title-1-8">
                          Top Monsoon Wedding Destinations
                        </h4>
                        <h5 class="">1. Udaipur, Rajasthan</h5>
                        <p class="mb-30">
                          Known for its famous Lake Palace, the capital of the
                          erstwhile Mewar Kingdom, Udaipur is the perfect choice
                          if you’re looking for a breathtaking destination for
                          your wedding. This city, located in southern
                          Rajasthan, is surrounded by the Aravalli mountains,
                          gardens and lakes that provide it with an otherworldly
                          charm. Splendid palaces and other architectural
                          masterpieces also make Udaipur a top choice for
                          weddings on a grand scale. Then there is the lush
                          greenery on all sides, with the serene waters of the
                          lakes and the cloudy sky, which create the perfect
                          ambience in which to get married.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon/Udaipur-Rajasthan-300x157.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">2. Kovalam, Kerala</h5>
                        <p class="mb-30">
                          If you love the beach and the sea as much as monsoons,
                          then Kovalam might be your ideal wedding destination.
                          This coastal town in Kerala has some of the most
                          beautiful beaches in the country, and a wedding here
                          can even serve as a vacation for your wedding guests.
                          Kovalam has several resorts and hotels, making this a
                          good choice for a monsoon wedding. Also, if you’re one
                          for dramatic entrances, there are options like
                          decorated boats that can be used for wedding purposes.
                          Alternatively, you can hold the wedding in the
                          backwaters on a houseboat, which has a romantic charm
                          of its own.
                        </p>

                        <h5 class="">3. Goa</h5>
                        <p class="mb-30">
                          While Goa is more famously known as a tourist spot, it
                          can also serve as an amazing destination for a monsoon
                          wedding. Since tourism takes a dip during the monsoon
                          season, you can expect to get good deals when
                          searching for suitable venues and resorts. Goa is not
                          only one of the most naturally picturesque cities in
                          India but also features awe-inspiring Portuguese
                          architecture and mouth-watering cuisine. Both church
                          and beach weddings are incredibly popular in this
                          small Indian state. Those planning to get married here
                          can even participate in colourful festivals, many of
                          which occur throughout the year. Plus, you must have
                          heard about Goa’s buzzing nightlife and beautiful
                          beaches that look resplendent, especially under a
                          moonlit sky. So, booking a beach resort for the big
                          day, might be ideal to plan your dream wedding.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon/Goa-300x157.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">4. Hyderabad, Telangana</h5>
                        <p class="mb-30">
                          Hyderabad is another option you can consider if you’re
                          planning a royal wedding. The magnificent architecture
                          here is a visual treat, and the location was once the
                          cultural epicentre of the region, which is why it has
                          quite a rich history. Additionally, the food in the
                          largest city and capital of Telangana is something to
                          be cherished. Hyderabad also has many hotels and
                          lakeside areas, perfect for tying the knot. The most
                          popular venues for monsoon weddings here include the
                          ITC Kakatiya and the Taj Falaknuma Palace.
                        </p>

                        <h5 class="">5. Andaman And Nicobar Islands</h5>
                        <p class="mb-30">
                          With beach weddings becoming popular in India, Andaman
                          and Nicobar Islands have quickly become one of the
                          most visited destinations to celebrate with friends
                          and family. Popular choices include Ross Island and
                          Havelock Island, which have beautiful sandy beaches
                          and stunning coral reefs, among other attractions that
                          make them perfect for a monsoon wedding. Andaman and
                          Nicobar Islands also make a great honeymoon
                          destination, so you can extend your stay after the
                          wedding and enjoy the amazing sights and sounds. For
                          those fond of nature, these islands are some of the
                          richest when it comes to biodiversity, with beautiful
                          marine ecosystems and lush evergreen forests.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon/Andaman-And-Nicobar-Islands-300x157.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">6. Khajuraho, Madhya Pradesh</h5>
                        <p class="mb-30">
                          Located in the Bundelkhand region of Madhya Pradesh,
                          Khajuraho is perhaps most famous for being one of the
                          seven wonders of India. Numerous temples serve as
                          stellar examples of the remarkable architecture of
                          ancient India and make this location an extremely
                          popular tourist destination. Because of this, the
                          number of luxury hotels and resorts has been steadily
                          growing over the past few years, which means making
                          arrangements for a wedding is not very difficult.
                          Tying the knot during the monsoon season here can be
                          quite enchanting, as the weather becomes very
                          pleasant, and both church and temple weddings are
                          pretty common.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon/Khajuraho-Madhya-Pradesh-300x157.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">7. Vadodara, Gujarat</h5>
                        <p class="mb-30">
                          While Vadodara is not a destination frequently
                          associated with monsoon weddings, it is, nevertheless,
                          an excellent choice because of many reasons. Firstly,
                          this riverside city has a rich culture that is quite
                          charming, while there are many colourful locations and
                          majestic forts that can be used for a lavish wedding.
                          The Lakshmi Vilas Palace is the most famous location
                          in Vadodara that is often used for destination
                          weddings. This palace, which is the residence of the
                          royal Maratha family of the state, offers various
                          banqueting facilities that make it an ideal choice for
                          a dream wedding.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon/Vadodara-Gujarat-300x157.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h4 class="">Final Words</h4>
                        <p class="mb-30">
                          Indian weddings are traditionally known to be grand
                          affairs, comprising numerous rituals, prayers,
                          ceremonies, and feasts. While this still holds true,
                          more and more individuals are opting for
                          unconventional weddings, among which destination
                          weddings are a particular favourite. And if you’re
                          planning a destination wedding during the monsoon, the
                          locations mentioned above are some of the best worth
                          considering. Almost all of these have resorts and
                          hotels that can help in making the arrangements, which
                          can be really helpful. All you need to do is research
                          the various options to determine which would be most
                          suitable for your dream wedding.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage5;
