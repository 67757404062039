import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage2 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          HOW TO PLAN YOUR WEDDING SANGEET?
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/blog-2.png")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove Feb 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          HOW TO PLAN YOUR WEDDING SANGEET?
                        </h4>

                        <p class="mb-25">
                          {" "}
                          Oh, to fall in love and get married thereafter – it’s
                          the stuff that dreams are made of! Honestly, there is
                          nothing better than marrying the love of your life in
                          a grand ceremony. And Indian weddings, in particular,
                          are not lacking in grandeur in any way, that’s for
                          sure! There’s a reason why it is called a “big, fat
                          Indian wedding,” after all. But if you want to make it
                          even more spectacular, then a sangeet ceremony is a
                          must. Some music and dance to commemorate one of the
                          most important nights of your life is never a bad idea
                          – believe us! The real question is – how can you plan
                          a wedding sangeet that will remain etched in your
                          memory forever? Well, that’s what this guide is all
                          about, for it details how to plan an epic sangeet
                          ceremony. So, if you wish to get hitched with a bang,
                          then read on!
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}
                        <h4 class="lv-blog-title-1-8">
                          How Can You Plan An Awesome Wedding Sangeet?
                        </h4>
                        <h5 class="">
                          1. Venue – Pick A Place That Fits The Vibe
                        </h5>
                        <p class="mb-30">
                          The first and most important thing you need to
                          consider is the venue. You can pick conventional
                          venues like hotel ballrooms and community centres, or
                          you can choose places like pubs and cafes to amp up
                          the party vibes. To take it a step further, you can
                          rent open-air venues like rooftop lounges, hotel lawns
                          and even some exotic locations like farmhouses or
                          beach resorts. Do note that such places will need to
                          have the necessary arrangements to protect guests from
                          bad weather. It is always a wise idea to pick a
                          spacious venue. If it has a dedicated stage, then it’s
                          a big plus. In this regard, you can set up additional
                          stations at the venue, like photo booths with
                          360-degree cameras. That way, your guests can have a
                          fun time capturing a few quirky memorable shots of
                          their own. Also, be sure to check the audio
                          arrangements since that is crucial. Similarly, you
                          have to make sure that the venue is easily accessible
                          and has all the necessary amenities present.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage2.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">2. Decor – Make The Ambience Count</h5>
                        <p class="mb-30">
                          No Indian wedding feels complete without decorations,
                          which is why this is another crucial aspect of your
                          wedding sangeet. Naturally, the traditional wedding
                          decor of flowers, carpets, lights and drapes will be
                          more than sufficient to set the tone and make the
                          event a success. But if you wish to make a statement,
                          you should pick a specific theme for your sangeet.
                          You’ll need to decorate every corner of the venue with
                          elements that fit your chosen theme, which will set
                          the ambience for the night. Needless to say – the more
                          energetic your theme is, the more fun it will be for
                          everyone! That said, keep yourself informed about some
                          of the hottest trends being followed right now. If you
                          wish to keep things simple, you may want to go with a
                          conventional pick – the Bollywood theme. However, if
                          you want to make your sangeet more glitzy, you can
                          pick a more glamorous theme, such as those inspired by
                          the Met Galas and red carpet events. Alternatively,
                          you can go with a classic or retro theme for your
                          decor if you wish to capture an old-school vibe.
                          Another great pick would be a rustic or village-based
                          theme to make your sangeet feel more organic and
                          unique. Apart from that, you can follow themes based
                          on your favourite movie franchises, such as Arabian
                          Nights, Harry Potter, and others.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage2.1.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          3. Music And Dance – Get The Party Started
                        </h5>
                        <p class="mb-30">
                          Now comes the most important components of a sangeet –
                          music and dance. Depending on your preferences, there
                          are a couple of ways to plan these aspects. For a
                          traditional sangeet experience, you can pick a
                          simplistic arrangement where the music is played on a
                          sound system as the couple and guests dance. A
                          playlist made up of hit Bollywood songs is the most
                          obvious way to go. You can pick songs that fit your
                          selected theme as well. Of course, the playlist can
                          also include a few romantic numbers that hold
                          significance to you and your better half. But if you
                          want a livelier sangeet, then you can make an
                          intricate musical arrangement. You can hire a DJ to
                          play tailored songs during the event. Similarly, you
                          can recruit a choreographer to organise entertaining
                          dance events to go with the music. Wish to make the
                          sangeet a bit more exciting? How about organising an
                          energetic karaoke night? That way, your guests will be
                          able to showcase their musical prowess, making the
                          event a lot more interesting!
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage2.2.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">4. Food – Satisfy Your Party Cravings</h5>
                        <p class="mb-30">
                          Of course, how could we forget about the food?
                          Naturally, all the guests and patrons will be famished
                          after dancing their hearts out. So, you must have a
                          lip-smacking menu to satisfy their cravings. You can
                          go with a cuisine made up of traditional Indian
                          dishes, or you can pick an exotic menu made up of
                          unique food items. It is also a good idea to have a
                          bar to quench the thirst of everyone attending the
                          sangeet. And if it includes a selection of liquor like
                          fine wines and champagnes, it gets even better!
                        </p>

                        <h5 class="">
                          5. Dress Code – Turn Heads with Your Fashion Sense
                        </h5>
                        <p class="mb-30">
                          The last (but not the least) arrangement that you need
                          to make is the dress code. Traditional Indian attire
                          like Sherwanis, Kurtas and Lehengas are ideal, but you
                          can customise it according to your needs. For
                          instance, if you like Western attire, you can wear
                          blazers and suits. Likewise, if you prefer regional
                          dresses like Ghagras or Sarees, you can pick those
                          too. Also, you have to make sure that the dress code
                          looks vibrant. After all, more colours are always
                          better for a wedding, for they will make you look more
                          stunning!
                        </p>

                        <h4 class="lv-blog-title-1-8">Final Words</h4>
                        <p class="mb-30">
                          So, that’s how you can plan a memorable wedding
                          sangeet. And don’t forget to book professional
                          videographers and photographers well in advance to
                          capture snippets from this event. That way, you can
                          fondly look back at it when both of you grow old.
                          Obviously, the budget can be a bit of a concern, which
                          is why you will need to carefully plan your finances
                          before proceeding. But hey – you don’t get married
                          every day, so we understand if you wish to go all out.
                          So, here’s wishing you a rocking sangeet and a
                          fairytale wedding with your beloved!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage2;
