import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Amruta&Aniket/Amruta&Aniketbanner.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const AmrutaAniket = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    portfolio1: require("../../assets/img/portfolio/Amruta&Aniket/1.webp"),
    portfolio2: require("../../assets/img/portfolio/Amruta&Aniket/2.webp"),
    portfolio3: require("../../assets/img/portfolio/Amruta&Aniket/3.webp"),
    portfolio4: require("../../assets/img/portfolio/Amruta&Aniket/4.webp"),
    portfolio5: require("../../assets/img/portfolio/Amruta&Aniket/5.webp"),
    portfolio6: require("../../assets/img/portfolio/Amruta&Aniket/6.webp"),
    portfolio7: require("../../assets/img/portfolio/Amruta&Aniket/7.webp"),
    portfolio8: require("../../assets/img/portfolio/Amruta&Aniket/8.webp"),
    portfolio9: require("../../assets/img/portfolio/Amruta&Aniket/9.webp"),
    portfolio10: require("../../assets/img/portfolio/Amruta&Aniket/10.webp"),
    portfolio11: require("../../assets/img/portfolio/Amruta&Aniket/11.webp"),
    portfolio12: require("../../assets/img/portfolio/Amruta&Aniket/12.webp"),
    portfolio13: require("../../assets/img/portfolio/Amruta&Aniket/13.webp"),
    portfolio14: require("../../assets/img/portfolio/Amruta&Aniket/14.webp"),
    portfolio15: require("../../assets/img/portfolio/Amruta&Aniket/15.webp"),
    portfolio16: require("../../assets/img/portfolio/Amruta&Aniket/16.webp"),
    portfolio17: require("../../assets/img/portfolio/Amruta&Aniket/17.webp"),
    portfolio18: require("../../assets/img/portfolio/Amruta&Aniket/18.webp"),
    portfolio19: require("../../assets/img/portfolio/Amruta&Aniket/19.webp"),
    portfolio20: require("../../assets/img/portfolio/Amruta&Aniket/20.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/21.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/22.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/23.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/24.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/25.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/26.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/27.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/28.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/29.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/30.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/31.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/32.webp"),
    portfolio21: require("../../assets/img/portfolio/Amruta&Aniket/33.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>

        <h2 className="text-white text-center text-[60px] z-0">
          {" "}
          Amruta & Aniket
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row justify-center gap-10 lg:px-10 px-2 py-10">
        <div class="lg:w-[500px]">
          <div class="lv-portfolio-details-top-wrap-1-4 mb-35">
            <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
              Amruta & Aniket
            </h4>

            <span class="lv-portfolio-date-1-4">
              January 2024, Kohinoor Continental, Mumbai
            </span>
          </div>
          <div class="lv-portfolio-details-content-1-4 mb-30">
            <h2>Amruta & Aniket: An Eternal Union</h2>
            <p class="mb-25">
              In the heart of vibrant Mumbai, amidst the dazzle of lights and
              the rhythm of celebration, Aniket and Amruta embarked on their
              journey of eternal love. Their wedding festivities began with the
              cherished Haldi ceremony, ablaze with joy and tradition. As
              twilight painted the sky in golden hues, their Mehendi cocktail
              party transformed their lawn into a magical oasis, adorned with
              fairy lights.
            </p>
            <p>
              Day two dawned with promises of new beginnings as they exchanged
              vows in a serene ceremony. The Kohinoor Continental Hotel became a
              canvas of elegance, adorned with intricate mandaps and cascading
              floral arches, reflecting the couple's unique style. The grand
              wedding reception echoed with cheers and music, as Aniket and
              Amruta's love filled the air with joy.
            </p>
            <p>
              Their wedding was more than a celebration; it was a testament to
              commitment, faith, and friendship. As they embark on this
              beautiful journey together, their love story continues to inspire
              all touched by its magic.
            </p>
          </div>
        </div>

        <div className="lg:w-[600px]">
          <div className="py-3">
            <img
              className=""
              src={require("../../assets/img/portfolio/Amruta&Aniket/3.webp")}
            />
          </div>
          <div className="flex gap-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top"
              style={{
                backgroundImage: `url(${images["portfolio1"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top"
              style={{
                backgroundImage: `url(${images["portfolio2"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio3"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio4"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio5"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio6"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio7"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio8"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-no-repeat  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio9"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio10"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio11"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio12"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio13"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio14"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio15"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio16"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio17"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio18"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio19"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio20"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio21"]})`,
              }}
            ></div>
            {/* <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio22"]})`,
              }}
            ></div> */}
          </div>
          {/* <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio23"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio23"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio24"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio25"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio26"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio27"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio28"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio29"]})`,
              }}
            ></div>
          </div> */}
          {/* <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio30"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio31"]})`,
              }}
            ></div>
          </div> */}
          {/* <div className="flex gap-3 flex-col lg:flex-row py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio32"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio33"]})`,
              }}
            ></div>
          </div> */}

          {/* Modal for displaying the clicked image */}
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Modal"
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AmrutaAniket;
