import React, { useEffect, useState } from "react";
import "./custom.css";
import gallery1 from "../../page/Gallery/TrunkinloveImg/7.png";
import gallery2 from "../../page/Gallery/TrunkinloveImg/8.png";
import gallery3 from "../../page/Gallery/TrunkinloveImg/9.png";
import gallery4 from "../../page/Gallery/TrunkinloveImg/4.png";
import gallery5 from "../../page/Gallery/TrunkinloveImg/5.png";
import gallery6 from "../../page/Gallery/TrunkinloveImg/6.png";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        // Trigger change at 50px scroll
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gallery1bg = {
    backgroundImage: `url(${gallery1})`,
  };
  const gallery2bg = {
    backgroundImage: `url(${gallery2})`,
  };
  const gallery3bg = {
    backgroundImage: `url(${gallery3})`,
  };
  const gallery4bg = {
    backgroundImage: `url(${gallery4})`,
  };
  const gallery5bg = {
    backgroundImage: `url(${gallery5})`,
  };
  const gallery6bg = {
    backgroundImage: `url(${gallery6})`,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handlsIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Inline styles for the sidebar
  const deshtopsidebar = {
    // position: "fixed",
    left: isOpen && !isMobile ? 0 : "-250px",
    top: 0,
    width: "400px",
    height: "100vh",
    // backgroundColor: "#ffffff",
    transition: "left 0.3s",
    padding: "10px",
  };
  const mobilesidebar = {
    position: "fixed",
    left: isOpen && isMobile ? 0 : "-100px",
    top: 0,
    width: "320px",
    height: "100%",
    backgroundColor: "#ffffff",
    transition: "left 0.3s",
  };

  // Inline styles for the overlay
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#0000004e",

    display: isOpen ? "block" : "none",
  };

  // Inline styles for the hamburger menu
  const hamburgerStyle = {
    cursor: "pointer",
    // Add more styling as needed
  };

  // console.log({ handlsIsOpen });
  return (
    <>
      {" "}
      {/* <!-- header area start --> */}
      <div className="">
        <div class="flex  justify-center items-center -z-0 -mb-[118px]">
          <div
            onClick={handlsIsOpen}
            style={hamburgerStyle}
            className="group grid justify-left items-center gap-1.5 lv-header-bar-1 mt-10 text-3xl text-white"
          >
            <span className="h-[2px] w-8 rounded-full bg-white"></span>
            <span className="h-[2px] w-5 group-hover:w-8 rounded-full bg-white"></span>
            <span className="h-[2px] w-8 rounded-full bg-white"></span>
          </div>

          <div class="col-xxl-8 col-xl-10 col-lg-10  col-md-6 col-8">
            <div class="pl-20 pr-20 lv-header-nav-middle">
              <div class="flex justify-center items-center">
                <div class="col-xxl-5 col-xl-5 col-lg-5 d-none d-lg-block">
                  <div class="lv-header-nav lv-header-nav-1 lv-header-left">
                    <nav id="mobile-menu">
                      <ul className="text-right pr-[30px]">
                        <li className="   ">
                          <a className="" href="/">
                            Home
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li className="">
                          <a className="no-underline " href="/about-us">
                            About Us
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>

                        <li className="">
                          <a className="" href="/services">
                            Services
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div class="col-xxl-2 col-xl-2 col-lg-2 -z-90">
                  <div class="lv-header-logo text-center -z-90">
                    <div class="lv-header-logo-box -z-90">
                      <a href="/">
                        <img
                          className="z-90"
                          src={require("../../assets/img/logo/logo (4).png")}
                          alt="logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-5 col-xl-5 col-lg-5 d-none d-lg-block">
                  <div class="lv-header-nav lv-header-nav-1 lv-header-right">
                    <nav>
                      <ul className="text-right">
                        <li className="">
                          <a className="" href="/portfolio">
                            Portfolio
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li className="">
                          <a className="" href="/gallery">
                            Gallery
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>

                        <li className="">
                          <a className="" href="/blogs">
                            Blogs
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li className="">
                          <a className="" href="/contact-us">
                            Contact Us
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="5"
                              viewBox="0 0 44 5"
                            >
                              <path
                                data-name="Path 49"
                                d="M-2222,971.5a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,971.5Zm7,.5v-1h17v1Zm-27,0v-1h18v1Z"
                                transform="translate(2242 -969)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lv-header-nav lv-header-nav-1 lv-header-left   hidden md:block z-2">
          <nav id="mobile-menu " className="">
            <ul className=""></ul>
          </nav>
        </div>
        <div className="lv-header-nav lv-header-nav-1 lv-header-left   hidden md:block z-2">
          <nav id="mobile-menu " className=""></nav>
        </div>
      </div>
      {/* <!-- Deshtop sidebar area start --> */}
      {isOpen && (
        <>
          <div
            onClick={handlsIsOpen}
            style={deshtopsidebar}
            className="lv-header-sidebar-area text-center md:block hidden sidebar"
          >
            <div class="lv-header-sidebar-wrapper">
              <div class=" text-[#6A6A6A] flex flex-row items-center justify-end gap-2 lv-header-sidebar-action text-end pb-35 cursor-pointer uppercase">
                <span>Close</span>
                <div className="uppercase  grid justify-items-center gap-1.5">
                  <span className=" h-[2px] w-8 rounded-full rotate-45 absolute  bg-[#6A6A6A]"></span>
                  <span className="h-[2px] w-8 rounded-full -rotate-45 bg-[#6A6A6A]"></span>
                  {/* <span className="h-[2px] w-8 rounded-full bg-black"></span> */}
                </div>
              </div>
              <div class="lv-header-sidebar-logo pb-50">
                <a href="/">
                  <img
                    className=""
                    src={require("../../assets/img/logo/logoPurple.jpg")}
                    alt="logo not found"
                  />
                </a>
              </div>
              <div class="mobile-menu"></div>
              <div class="lv-footer-touch-widget-box lv-header-sidebar-touch-widget text-center pb-[30px]">
                <h4 className="text-3xl uppercase ">Get in touch</h4>
                <div classNam="pb-20">
                  <a
                    className="text-sm no-underline !text-[#000066]"
                    href="mailto:akshaay@trunnkinlove.com"
                  >
                    <p>akshaay@trunnkinlove.com</p>
                  </a>

                  <a
                    className="text-sm no-underline !text-[#000066]"
                    href="tel:+919820286209"
                  >
                    <p>+91 9820-286-209</p>
                  </a>

                  <p className="!text-[#000066]">
                    304 B wing, Kotia Nirman, New link road, Near Fun republic,
                    Andheri west, Mumbai – 400 058.
                  </p>
                </div>
              </div>
              <div className="flex  justify-center pb-20 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="125"
                  height="5"
                  viewBox="0 0 125 5"
                >
                  <g transform="translate(-111 -398)">
                    <circle
                      data-name="Ellipse 105"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(171 398)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 467"
                      width="50"
                      height="1"
                      transform="translate(111 400)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 468"
                      width="50"
                      height="1"
                      transform="translate(186 400)"
                      fill="#777"
                    />
                  </g>
                </svg>
              </div>
              <div class="lv-header-sidebar-official-gallery text-center pb-15">
                <h4 class="lv-footer-title">official Gallery</h4>
                <div class="row g-10  pt-20">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery1bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery2bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery3bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery4bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery5bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery6bg}
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="flex  justify-center pb-20 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="125"
                  height="5"
                  viewBox="0 0 125 5"
                >
                  <g transform="translate(-111 -398)">
                    <circle
                      data-name="Ellipse 105"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(171 398)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 467"
                      width="50"
                      height="1"
                      transform="translate(111 400)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 468"
                      width="50"
                      height="1"
                      transform="translate(186 400)"
                      fill="#777"
                    />
                  </g>
                </svg>
              </div>
              <div class="footer-widget lv-header-sidebar-widget text-center">
                <h4 class="lv-footer-title">Follow Us</h4>
                <div class="lv-footer-social-link">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/trunnkinlove?tsid=0.44050175965029004&source=result"
                    className="text-xl no-underline !text-[#000066] after:content-[''] "
                  >
                    <i class="fa-brands fa-facebook text-2xl"></i>
                  </a>
                  {/* <a href="#" className="text-sm no-underline text-black">
                    <p>Twitter</p>
                  </a> */}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/trunnkinlove/?igshid=tljgr5oqa7ob"
                    className="text-xl no-underline !text-[#000066]"
                  >
                    <i class="fa-brands fa-instagram text-2xl"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {isOpen && <div style={overlayStyle} onClick={handlsIsOpen}></div>} */}
      {/* <!-- Deshtop sidebar area end --> */}
      {/* ========================= */}
      {/* <!-- Moile sidebar area start --> */}
      {isOpen && (
        <>
          <div
            style={mobilesidebar}
            className="lv-header-sidebar-area text-center md:hidden "
          >
            <div class="lv-header-sidebar-wrapper -z-1 ">
              <div
                onClick={handlsIsOpen}
                class=" text-[#6A6A6A] flex flex-row items-center justify-end gap-2 lv-header-sidebar-action text-end  pt-25 cursor-pointer uppercase"
              >
                <span>Close</span>
                <div className="uppercase  grid justify-items-center gap-1.5">
                  <span className=" h-[2px] w-8 rounded-full rotate-45 absolute  bg-[#6A6A6A]"></span>
                  <span className="h-[2px] w-8 rounded-full -rotate-45 bg-[#6A6A6A]"></span>
                  {/* <span className="h-[2px] w-8 rounded-full bg-black"></span> */}
                </div>
              </div>
              <div class="lv-header-sidebar-logo  ">
                <a href="index.html">
                  <img
                    className=""
                    src={require("../../assets/img/logo/logoPurple.jpg")}
                    alt="logo not found"
                  />
                </a>
              </div>
              <div class=" text-left pb-4">
                <ul className=" uppercase shadow-2xl">
                  <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a className="no-underline text-white text-lg" href="/">
                      Home
                    </a>
                  </li>
                  <li className="bg-[#000066] border-b-[1px] px-3  py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/about-us"
                    >
                      About Us
                    </a>
                  </li>
                  {/* <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a className="no-underline text-white text-lg" href="/team">
                      Team
                    </a>
                  </li> */}
                  <li className="bg-[#000066] border-b-[1px] px-3  py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/services"
                    >
                      Services
                    </a>
                  </li>
                  <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/portfolio"
                    >
                      Portfolio
                    </a>
                  </li>
                  <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/gallery"
                    >
                      Gallery
                    </a>
                  </li>
                  <li className="bg-[#000066] border-b-[1px] px-3  py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/blogs"
                    >
                      Blogs
                    </a>
                  </li>
                  {/* <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a className="no-underline text-white text-lg" href="/">
                      OUR CLIENTS
                    </a>
                  </li> */}
                  <li className="bg-[#000066] border-b-[1px] px-3 py-2">
                    <a
                      className="no-underline text-white text-lg"
                      href="/contact-us"
                    >
                      CONTACT US
                    </a>
                  </li>
                </ul>
              </div>
              <div class="lv-footer-touch-widget-box lv-header-sidebar-touch-widget text-center">
                <h4 className="text-[18px] uppercase ">Get in touch</h4>
                <div classNam="pb-20 text-[#000066]">
                  <a
                    className="text-[14px] no-underline !text-[#000066]"
                    href="mailto:akshaay@trunnkinlove.com"
                  >
                    <p>akshaay@trunnkinlove.com</p>
                  </a>

                  <a
                    className="text-[14px] no-underline !text-[#000066]"
                    href="tel:+919820286209"
                  >
                    <p>+91 9820-286-209</p>
                  </a>

                  <p className="text-[14px] px-10 !text-[#000066]">
                    306 B wing, Kotia Nirman, New link road, Near Fun republic,
                    Andheri west, Mumbai – 53.
                  </p>
                </div>
              </div>
              <div className="flex  justify-center pb-20 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="125"
                  height="5"
                  viewBox="0 0 125 5"
                >
                  <g transform="translate(-111 -398)">
                    <circle
                      data-name="Ellipse 105"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(171 398)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 467"
                      width="50"
                      height="1"
                      transform="translate(111 400)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 468"
                      width="50"
                      height="1"
                      transform="translate(186 400)"
                      fill="#777"
                    />
                  </g>
                </svg>
              </div>
              <div class="lv-header-sidebar-official-gallery text-center pb-15">
                <h4 class="lv-footer-title">official Gallery</h4>
                <div class="row g-10  pt-20">
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery1bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery2bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery3bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery4bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery5bg}
                      ></div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                    <a href="/gallery">
                      <div
                        class="lv-header-sidebar-official-gallery-item mb-10 bg-default"
                        style={gallery6bg}
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="flex  justify-center pb-20 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="125"
                  height="5"
                  viewBox="0 0 125 5"
                >
                  <g transform="translate(-111 -398)">
                    <circle
                      data-name="Ellipse 105"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(171 398)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 467"
                      width="50"
                      height="1"
                      transform="translate(111 400)"
                      fill="#777"
                    />
                    <rect
                      data-name="Rectangle 468"
                      width="50"
                      height="1"
                      transform="translate(186 400)"
                      fill="#777"
                    />
                  </g>
                </svg>
              </div>
              <div class="footer-widget lv-header-sidebar-widget text-center">
                <h4 class="lv-footer-title ">Follow Us</h4>
                <div class="lv-footer-social-link ">
                  <span className="">
                    <a
                      href="#"
                      className="no-underline text-sm  !text-[#000066]"
                    >
                      <i class="fa-brands fa-facebook "></i>
                    </a>
                  </span>

                  {/* <span className="text-sm  text-black">
                    <a href="#" className="no-underline text-sm  text-black">
                      Twitter
                    </a>
                  </span> */}

                  <span className="text-sm  !text-[#000066]">
                    <a
                      href="#"
                      className="no-underline text-sm  !text-[#000066]"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOpen && <div style={overlayStyle} onClick={handlsIsOpen}></div>}
      {/* <!-- Moile sidebar area end --> */}
    </>
  );
};

export default Header;
