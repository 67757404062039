import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage7 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          Colour Trends In The Wedding Industry 2024
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/38.png")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove June 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          Colour Trends In The Wedding Industry 2024
                        </h4>

                        <p class="mb-25">
                          {" "}
                          One of the biggest challenges usually associated with
                          weddings is finding the right outfit. <br />
                          You may be able to find a dress that has the most
                          exquisite details or is made of the most luxurious and
                          comfortable material. But it is often the colour that
                          is most difficult to select. This is because, like
                          most other fashion elements, colours can come and go
                          out of style. And you wouldn't want to be seen wearing
                          a colour that went out of fashion decades ago. That's
                          why we've put together a brief guide on the colour
                          trends expected in the wedding industry this year.
                        </p>

                        <h3 className="text-[20px] mb-30">
                          Wedding Industry Colour Trends 2024
                        </h3>
                        <h5 class="">1. Red</h5>
                        <p class="mb-30">
                          The colour red is, if not the first, definitely one of
                          the first to come to mind when it comes to Indian
                          weddings. It’s a colour associated with strength,
                          passion, and love, and wedding themes incorporating
                          red are ideal if you want to bring a sense of
                          intensity and romance. While the popularity of this
                          colour did see a dip during the 2010s, it has
                          witnessed a resurgence among wedding colours in recent
                          years. That is why you can find a wide selection of
                          bridal wear from famous designers like Sabyasachi,
                          including sarees and lehengas in this bold colour.
                          Furthermore, red is among those colours that go well
                          with many other options and can be complimented using
                          both dark and light colours. And for a wedding dress,
                          it is the perfect colour that can be used with
                          intricate handwork and contemporary designs to help
                          create a lasting impression.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/39.png")}
                            alt=""
                            className="w-[600px] m-auto "
                          />
                        </p>
                        <h5 class="">2. Cinnamon</h5>
                        <p class="mb-30">
                          Cinnamon is among those wedding colours that might be
                          an excellent choice if you're looking for an
                          unconventional option. Compared to the rust colour,
                          which was among the most popular wedding colours of
                          2022, cinnamon has slightly warmer undertones, which
                          provide a more refreshing feel. Despite being an
                          offbeat colour, cinnamon manages to draw attention
                          where needed expertly but does so in a subtle manner.
                          This makes it perfect for situations where you might
                          wish to avoid bolder wedding colours like red. And if
                          you’re wondering how to use cinnamon effectively,
                          cinnamon colour schemes can easily be accented with
                          neutral shades, contrasting blues, or deep burgundy to
                          enhance their effect.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/41.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">3. Viva Magenta </h5>
                        <p class="mb-30">
                          Any guide on wedding colour trends for 2023 would be
                          incomplete if it did not include the Pantone colour of
                          the year, Viva Magenta. Like red, this colour
                          expresses strength and is quite a common wedding
                          colour for Indian weddings since it is also associated
                          with celebration, confidence, and boldness. One reason
                          Viva Magenta is among the top Indian wedding colours
                          for 2023 is that it can highlight Indian skin very
                          effectively. And it is a royal colour that can be used
                          in combination with other wedding colours like navy
                          blue and emerald green or even with earthy hues like
                          cinnamon brown. This means it is possible to achieve a
                          luxurious appearance with minimal effort. Moreover,
                          embroideries in complementing colours, like gold,
                          stand out well in this punchy colour, making it ideal
                          for your wedding.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/40.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">4. Aqua Blue</h5>
                        <p class="mb-30">
                          If you're looking for an alternative to bold and
                          intense colours, aqua blue might be a fantastic
                          choice. This soothing colour has been quite popular in
                          pre-wedding festivities in these starting months, and
                          the trend is expected to continue throughout the year.
                          Apart from bridal dresses, aqua blue can often be seen
                          on bridesmaid dresses. This colour symbolises
                          youthfulness and reinvigoration and is most commonly
                          associated with calmness and freshness. And if you're
                          looking for an aesthetic that balances traditional and
                          contemporary ideas, there is no better choice since it
                          is a timeless colour. Those preferring brighter
                          wedding colours like aqua blue will be happy to know
                          that they can be easily paired with muted shades like
                          grey or blush. Aqua blue is also great for
                          unconventional wedding themes, such as a beach
                          wedding, where bolder options like red may not be a
                          great choice.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/42.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">5. Ivory</h5>
                        <p class="mb-30">
                          Ivory became a popular wedding colour only recently,
                          but it is expected to be pretty popular for weddings
                          in 2023. While quite similar to white, ivory has a
                          slightly softer, creamier appearance and exudes warmer
                          and richer vibes. This provides it with a
                          sophisticated and elegant appearance that can be used
                          to create a more impressive look. As a 2023 wedding
                          colour, you can expect ivory to be most visible on
                          occasions like receptions, and ivory lehengas have
                          especially become a huge hit. And since it is a
                          neutral colour, it can be used in various
                          combinations, such as with gold or champagne. The
                          ivory colour has been used for centuries to create
                          decorative objectives since it symbolises purity. This
                          is another reason it is a fantastic pick for your
                          wedding. While it also creates a sense of calmness
                          like white, the added element of luxury makes it a
                          better choice as a wedding colour.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/40.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">6. Greens</h5>
                        <p class="mb-30">
                          Another of the newest wedding colour trends is the
                          increasing popularity of shades of green, such as
                          emerald and mint green. Traditionally, you could find
                          green only on bridesmaid dresses, but more and more
                          brides are opting for this refreshing shade this year.
                          Green colour schemes work well for weddings held both
                          in spring and summer. The most common are lighter
                          shades of green, often found in combination with
                          bolder colours like gold and magenta. Mint green,
                          which went out of fashion around 2012, is also making
                          a comeback on the wedding colour palette of this year,
                          often alongside neutral shades like beige and white.
                          But you can even find sea, moss, and sage green during
                          the wedding season. Forest green is another option
                          that also goes well with shades like blush pink and is
                          a great choice for a spring wedding. But irrespective
                          of which shade of green you pick, this colour, which
                          symbolises new beginnings, renewal, and growth, is
                          expected to be very popular in 2023.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Colour Trends In The Wedding Industry 2024/43.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h4 class="">Final Thoughts</h4>
                        <p class="mb-30">
                          If you are looking for the perfect wedding colour
                          scheme to go with this year, there are many to choose
                          from these range from traditional red and magenta hues
                          to contemporary choices like ivory and green. All of
                          the shades mentioned here are expected to be the most
                          prominent ones among the wedding trends of this year.
                          However, selecting the perfect one requires
                          considering a lot of factors, the most important being
                          the season and location. It is also a good idea to
                          take some time out from the preparations and conduct
                          proper research, which can help ensure you look
                          perfect on your wedding day.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage7;
