import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage6 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          DO’S & DON’TS FOR A MONSOON WEDDING
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/banner.jpeg")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove May 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          DO’S & DON’TS FOR A MONSOON WEDDING
                        </h4>

                        <p class="mb-25">
                          {" "}
                          Once engaged, many couples face the decision of
                          choosing the time of the year to get married.
                          Oftentimes, the date they choose to tie the knot lands
                          squarely in the middle of the rainy season. Perhaps
                          the wait until the winter ends up being too long, or
                          the most auspicious date for the wedding is during the
                          monsoon. No matter the reason, a sudden downpour
                          spells disaster like no other for your perfect wedding
                          ceremony. The floral decorations, the delicious food
                          and the wedding ceremony itself may get washed away by
                          the relentless monsoon showers. As such, it’s of the
                          utmost importance that you remain prepared to tackle
                          the challenges of a monsoon wedding. So, here are a
                          few things you should consider if you have no other
                          option but to opt for a monsoon wedding. Learn all the
                          elements you can add and the things you can avoid to
                          make the event as memorable as possible!
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}

                        <h5 class="">1. Choose An Indoor Venue</h5>
                        <p class="mb-30">
                          While making preparations for a monsoon wedding,
                          consider choosing an indoor venue. After all, an
                          open-air wedding in the rain is far from the ideal
                          prospect. Not only will it sully your wedding attire,
                          but it will also cause your guests discomfort. And of
                          course, people prefer dry and well-ventilated venues
                          for weddings. Alternatively, you can choose an outdoor
                          venue that is well-covered and equipped to protect the
                          guests and all the other arrangements from sudden
                          rain.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/1.jpeg")}
                            alt=""
                            className="w-[600px] m-auto "
                          />
                        </p>
                        <h5 class="">
                          2. Ensure That The Decor Matches The Season
                        </h5>
                        <p class="mb-30">
                          Monsoon-themed decor can do wonders for the aesthetics
                          of your wedding ceremony. A well-planned
                          monsoon-themed wedding can appear magical. Have the
                          tables or tents adorned with seasonal flora like
                          lilies, lotuses, sunflowers and jasmines, adding a
                          jovial and warm feel to the venue. Note that some of
                          these species may react poorly to being exposed to
                          rain, in which case you may swap them for artificial
                          variants. Consider making it vibrant and using rainy
                          hues to make the brighter colours stand out even more.
                          Blues and greens go a long way in adding that magical
                          touch to the decorum. Moreover, fabrics like
                          satin-silk can make the backgrounds rich and elegant,
                          enhancing the colourful essence of the flora. Spray in
                          a few droplets of artificial raindrops to the decor,
                          and transform the venue into a magical wonderland!
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/2.jpeg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">3. Rain-Themed Outfits</h5>
                        <p class="mb-30">
                          The ones tying the knot can do so in an outfit that
                          matches the feel of the weather. With pastel colours
                          like peach, pink, blue, or yellow, you and your
                          soon-to-be spouse can complement the theme well. Such
                          colours go exceedingly well with breathable fabrics
                          that retain their beauty even when under light rain.
                          And, if possible, try not to use artificial jewellery
                          in the monsoon season. The rain and humidity may
                          damage the jewellery and potentially cause an allergic
                          reaction in the process. So, it’s better to go for
                          jewellery with pure metals and gems.
                        </p>

                        <h5 class="">
                          4. Arrange A Party Around The Monsoon Season
                        </h5>
                        <p class="mb-30">
                          People love a vibrant and energetic celebration, and a
                          party held with the weather in mind can elevate the
                          celebratory vibes to a new level. A rain dance party
                          can be a part of your pre- or post-wedding event. Feel
                          free to create some room in the busy pre- or
                          post-wedding schedule and partake in the event in
                          everyone’s presence. A rain dance party is sure to
                          remain in everyone’s fond memories for a long time.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/3.jpeg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">5. Get Creative With The Arrangements</h5>
                        <p class="mb-30">
                          While approaching the more mundane aspects of a
                          wedding arrangement requires a practical mindset, it
                          needn’t necessarily be so. Once you have the practical
                          aspects of the arrangements planned, you can get
                          creative with them. For instance, the seating
                          arrangements require a systematic breakdown of the
                          available space and bringing in chairs as necessary.
                          Feel free to add tables with monsoon blooms and
                          bouquets arranged in an artistic fashion. Throw in a
                          few elements of your personality and make the event
                          truly your own.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/4.jpeg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h4 className="lv-blog-title-1-8">
                          Don’ts For A Monsoon Wedding: What You Must Avoid
                        </h4>

                        <h5 class="">1. Refrain From Donning Heavy Fabrics</h5>
                        <p class="mb-30">
                          Fabrics like velvet do not go well with the rainy
                          season, where moisture is abundant. These materials
                          don’t dry easily and can cause plenty of discomfort
                          for the duration of the event. The same applies to
                          heavily embroidered attires, which can become quite
                          heavy should they absorb any moisture.
                        </p>

                        <h5 class="">2. No Warm Colours At The Venue</h5>
                        <p class="mb-30">
                          The monsoon is the time to play around with blues and
                          greens, the colours which complement the very essence
                          of the weather. As such, any warmer shades in the
                          venue may detract from the general vibes of the
                          season. Reds and oranges are such warm hues that you
                          must keep away from when having the venue decorated
                          for a monsoon theme.
                        </p>
                        <h5 class="">3. No Makeup Based On Heavy Foundation</h5>
                        <p class="mb-30">
                          Waterproof makeup and skincare products are a must in
                          the monsoon. It’s never pleasant when your makeup
                          becomes patchy in the humid weather, after all. Be
                          sure to use flawless primer and keep your makeup from
                          melting away in all circumstances. Usually,
                          brides-to-be use an oil-free primer to keep the base
                          strong and well-set throughout the wedding.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/Monsoon wedding do's don'nt/5.jpg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h4 class="">Final Words</h4>
                        <p class="mb-30">
                          Planning a monsoon wedding can be quite tedious,
                          bringing with it the need to consider the ever-looming
                          threat of sudden rain. With the tips listed above, you
                          can work around most of the hassles associated with
                          making arrangements during the rainy season. That
                          said, it’s important to plan ahead while keeping all
                          circumstances in mind. A simple contingency plan to
                          account for an element of your planning falling out of
                          place may very well save you plenty of trouble! And
                          lastly, be sure to remain mindful of the comfort of
                          everyone involved! A wedding is the union of two
                          souls, and the more that join in merrily, the more
                          memorable it becomes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage6;
