export const FooterSecondRoutes = [
  {
    title: "+91 9820-286-209",
    href: "tel:+91 9820-286-209",
    icon: <i class="fa-solid fa-phone "></i>,
  },
  {
    title: "akshaay@trunnkinlove.com",
    href: "mailto:akshaay@trunnkinlove.com",
    icon: <i class="fa-solid fa-envelope "></i>,
  },

  // Add more subpages as needed
];
