import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage3 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          DESTINATION WEDDINGS DO’S & DON’TS
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/blog-3.png")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove Jan 1,
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          DESTINATION WEDDINGS DO’S & DON’TS
                        </h4>

                        <p class="mb-25">
                          {" "}
                          A dream destination wedding is a bucket-list favourite
                          for many people for all the right reasons! After all,
                          what can be better than entering the holy matrimony
                          with the love of your life, surrounded by your loved
                          ones in a picturesque location? But we know how
                          difficult it can be to plan everything, especially if
                          the location is new to you. That’s why we are here to
                          help you by listing some of the do’s and don’ts for
                          your big destination D-day. Dive in now!
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}
                        <h4 class="lv-blog-title-1-8">
                          5 Things You MUST Do When Planning A Destination
                          Wedding
                        </h4>
                        <h5 class="">1. Keep Accessibility In Mind</h5>
                        <p class="mb-30">
                          The geographical diversity of India gives you ample
                          scope to choose your dream wedding location. You can
                          choose to take your vows on the serene beaches of Goa,
                          a royal fort in Rajasthan, or at the foot of the
                          mountains in Shimla. But you should keep in mind how
                          accessible the city is, at least from the place where
                          most of your guests will be coming from. For instance,
                          there are only two non-stop flights from Delhi to
                          Jaipur (one in the morning and one in the afternoon),
                          meaning you will have to plan your wedding festivities
                          accordingly. And flights from different states or
                          cities with 2 or more stops will invariably cost more-
                          something your guests should know. Besides, the timing
                          of your guests’ arrival (most of them, if not all)
                          will help you determine the time of your rituals. So,
                          if most of your guests arrive late in the evening,
                          you’re better off starting the rituals and functions
                          the following day.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Keep Accessibility In Mind.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          2. Prepare An Itinerary Well In Advance
                        </h5>
                        <p class="mb-30">
                          As a rule of thumb, prepare your wedding itinerary at
                          least 5 to 6 months in advance to give your guests
                          enough time to purchase flight tickets and get the
                          best deals. Moreover, working people would need
                          adequate time to plan for leaves. You can prepare
                          small ‘save the dates’ cards to send along with the
                          invites or design a wedding website with all the
                          important details. Don’t forget to mention the venue
                          and time of each function as well.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Prepare An Itinerary Well In Advance.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">
                          3. Check with The Hotel About Accommodation
                        </h5>
                        <p class="mb-30">
                          When planning a destination wedding, confirming
                          accommodation plans with the hotel or venue your
                          guests will stay at is extremely important. By this,
                          we don’t only mean confirming the accommodation for
                          your guests but also if any other large groups will
                          occupy the venue during the specified dates. After
                          all, there’s nothing worse than a bunch of sales reps
                          taking the pool by storm at the same time as one of
                          your festivities outdoors! It’s highly likely that you
                          will have to share the space with other ‘residents’ of
                          the venue (especially if it’s a hotel). So, speak with
                          the management to make sure there are no disturbances
                          to your guests or the events.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Check with The Hotel About Accommodation.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">4. Do A Recce</h5>
                        <p class="mb-30">
                          One of the best things you can do is plan for a site
                          recce (if you can afford to). This will give you a
                          practical idea of the venue and its surroundings,
                          which will, in turn, come in handy during the planning
                          and preparation. For example, you can designate
                          separate areas for the bride and groom if you plan to
                          have joint functions like Haldi. Besides, you can
                          arrange special assistance, such as wheelchairs for
                          the elderly people on your guest list if you deem fit.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Do A Recce.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">5. Keep An Eye On The Weather</h5>
                        <p class="mb-30">
                          Once you’ve decided on a location, make sure you do
                          ample research to understand the weather conditions of
                          the area during your wedding dates. For example, the
                          monsoon in Goa peaks during June- August. So, you
                          surely wouldn’t want to get married during intense
                          rainstorms, especially if it’s a beach wedding.
                          Moreover, knowing about the weather will help you
                          arrange for standing fans or additional cooling and
                          ventilation devices if the days get too warm or humid.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Keep An Eye On The Weather.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h4 class="lv-blog-title-1-8">
                          What Not To Do When Planning A Destination Wedding?
                        </h4>
                        <p class="mb-30">
                          When it comes to destination wedding etiquette,
                          knowing what not to do is just as important as knowing
                          the do’s. As such, you should avoid:
                        </p>
                        <h5 class="">1. Last Minute RSVPs</h5>
                        <p class="mb-30">
                          Planning for accommodation for hundreds of people can
                          be a daunting challenge, both financially and
                          mentally. And the most effective way to take some of
                          the load off your shoulders is to ask your guests to
                          RSVP at least a couple of months earlier. This will
                          give you a near-precise idea of how to go about
                          accommodation and transportation. That said, keep in
                          mind that there may be some last-minute entries, but
                          don’t shy away from emphasising the need for
                          confirmation in advance. Likewise, make sure to ask
                          your guests to inform you immediately if their travel
                          plans get changed or cancelled.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Last Minute RSVPs.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          2. Burning Through Your Budget On Decoration
                        </h5>
                        <p class="mb-30">
                          The whole point of a destination wedding is to indulge
                          in the natural beauty of the location you choose,
                          especially in the case of an outdoor wedding. And
                          going heavy on decor will invariably take away from
                          it. We’d suggest going for a minimalist theme,
                          complementing the natural surroundings and the time of
                          the day the wedding ceremony is planned for. Not only
                          will this prove to be cost-effective, but it will also
                          allow you to focus on other important factors like
                          accommodation, transportation, and food.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Burning Through Your Budget On Decoration.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          3. Accommodating All Guests at a Single Place
                        </h5>
                        <p class="mb-30">
                          When planning an accommodation budget, you may find
                          that keeping all guests at the venue (if it’s a
                          palace, hotel or resort) is becoming too expensive.
                          And that’s perfectly okay. In such a case, you may
                          look for other accommodations nearby that are more
                          affordable and divide your guests between the two
                          locations. You can accommodate your closest family
                          members and people involved in the rituals at the
                          venue for convenience.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/Accommodating All Guests at a Single Place.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h4 class="">Final Words</h4>
                        <p class="mb-30">
                          And that’s all we had for you today! But before we
                          wrap up: here’s a final pro tip, if you’re hiring a
                          wedding planner, be sure you communicate your
                          requirements properly to them well ahead of time. This
                          can save you a lot of trouble and last-minute
                          unnecessary jitters!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage3;
