import React from "react";
import HeroImage from "../../assets/img/slider/aboutuscontent.jpg";
import aboutsusgallery from "../../assets/img/slider/aboutus.jpg";

const AboutUs = () => {
  const containerStyle = {
    background: `url(${aboutsusgallery}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  // const aboutusStyle = {
  //   background: "url(" + HeroImage + ") center/cover ",
  //   backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
  //   height: "100vh", // Makes the height of the container equal to the height of the viewport
  //   width: "100%", // Ensures the container stretches to the full width of the viewport
  // };

  console.log(AboutUs);
  return (
    <div>
      <div
        style={containerStyle}
        className="flex   items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[40px] z-0">About Us</h2>
      </div>

      <div class="lv-about-area my-[80px]">
        <div class="container">
          <div class="flex flex-wrap justify-center space-y-3 ">
            <div class="">
              <div class="lv-about-img-wrap-1-1 pr-30">
                <img
                  className=""
                  src={require("../../assets/img/slider/aboutuscontent.jpg")}
                />
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6">
              <div class="lv-about-content-right lv-about-content-right-1-1">
                <div class="lv-about-content-wrap text-[#000066]">
                  <p class="lv-about-content mb-35">
                    A wedding consultancy service that is here to take away all
                    your wedding planning woes! We understand you and we
                    understand weddings. We are based in Mumbai but can travel
                    miles to make your wedding day special. We’re a team of
                    dedicated professionals with a cumulative experience of more
                    than a decade and network with the best vendors across India
                    to make your wedding dream come true. We’re here to give you
                    ideas, information, resources, and the best of memories for
                    your big day.
                  </p>
                  <p class="lv-about-content mb-35">
                    We put the plan in motion to ensure that you can enjoy your
                    wedding without having to worry about all the planning. In a
                    world ridden with anxiety, our team has a unique trait to
                    keep your Bridechilla mode on!
                  </p>
                  <p class="lv-about-content mb-35 font-semibold">
                    After all, your happiness is what gets us going!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" ontainer p-rel flex flex-col justify-center items-center text-center py-8 bg-[#F8F6F4]">
        <div class="mr-[1000px]">
          <div class="">
            <img
              className="w-[160px]"
              srcSet={require("../../assets/img/bg/testimonial-bg-1.webp")}
              alt="img not found"
            />
          </div>
        </div>

        <div className="text-[#000066]">
          <h3 className="lg:text-[49px] text-[#000066]">Vision</h3>
          <p className="lg:w-[600px]  md:px-0 px-2 pb-2">
            To add life to a once in a lifetime wedding, create moments etched
            in your memory forever and bring your dream wedding to life
          </p>
          <h3 className="lg:text-[49px] mt-20 text-[#000066]">Mission</h3>
          <p className="lg:w-[600px]  md:px-0 px-2">
            Matches are made in heaven; memorable weddings are made when you’re
            Trunnk in Love. We’re here on a mission to help you add life to your
            once in a lifetime event – your wedding. We want you to trust us as
            a confidant, negotiator and your wedding expert. We’re here to serve
            you with personalized wedding plans and are committed to perfection.
          </p>
        </div>
        <div class="ml-[1000px]">
          <div class="">
            <img
              className="-rotate-180 w-[160px]"
              srcSet={require("../../assets/img/bg/testimonial-bg-1.webp")}
              alt="img not found"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
