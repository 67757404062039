import React, { useState } from "react";
import HeroImage from "../../assets/img/slider/blogbanner.jpg";
import Author from "../../assets/img/portfolio/s6.webp";

// Sample blog data
const blogsData = [
  {
    id: 1,
    image: `${require("../../assets/img/blog/Colour Trends In The Wedding Industry 2024/38.png")}`,
    author: "trunnkinlove",
    date: "June 1, 2024",
    title: "Colour Trends In The Wedding Industry 2024",
    summary:
      "One of the biggest challenges usually associated with weddings is finding the right outfit. You may be able to find a dress that has the most exquisite details or is made of the most luxurious and comfortable material.",
    link: "/colour-trends-in-the-wedding-industry-2024",
  },
  {
    id: 2,
    image: `${require("../../assets/img/blog/Monsoon wedding do's don'nt/banner.jpeg")}`,
    author: "trunnkinlove",
    date: "May 1, 2024",
    title: "DO’S & DON’TS FOR A MONSOON WEDDING",
    summary:
      "Oftentimes, the date they choose to tie the knot lands squarely in the middle of the rainy season. Perhaps the wait until the winter ends up being too long, most auspicious date wedding is during the monsoon....",
    link: "/dos-donts-for-a-monsoon-wedding",
  },
  {
    id: 3,
    image: `${require("../../assets/img/blog/Monsoon/banner.png")}`,
    author: "trunnkinlove",
    date: "April 1, 2024",
    title: "MONSOON WEDDING DESTINATIONS",
    summary:
      "There are several amazing destinations to choose from, each offering beautiful vistas, fantastic cuisine, and more. But with such weddings that are to be held during the monsoon, finding the perfect location....",
    link: "/monsoon-wedding-destinations",
  },
  {
    id: 4,
    image: `${require("../../assets/img/blog/blog-1.png")}`,
    author: "trunnkinlove",
    date: "March 1, 2024",
    title: "GUIDE TO A SUSTAINABLE WEDDING",
    summary:
      "Weddings are meant to be the greatest day of everyone’s life. While it’s tempting to think of a wedding as a one-day event, your D-day could have a great impact on the environment....",
    link: "/guide-to-a-sustainable-wedding",
  },
  {
    id: 5,
    image: `${require("../../assets/img/blog/blog-2.png")}`,
    author: "trunnkinlove",
    date: "Feb 1, 2024",
    title: "HOW TO PLAN YOUR WEDDING SANGEET?",
    summary:
      "Oh, to fall in love and get married thereafter – it’s the stuff that dreams are made of! Honestly, there is nothing better than marrying the love of your life in a grand ceremony....",
    link: "/how-to-play-your-wedding-sangeet?",
  },
  {
    id: 6,
    image: `${require("../../assets/img/blog/DESTINATION WEDDINGS DOS & DONTS/2.png")}`,
    author: "trunnkinlove",
    date: "Jan 1, 2024",
    title: "DESTINATION WEDDINGS DO’S & DON’TS",
    summary:
      "A dream destination wedding is a bucket-list favourite for many people for all the right reasons! After all, what can be better than entering the holy matrimony with the love of your life...",
    link: "/destination-weddings-dos-and-donts",
  },
  {
    id: 7,
    image: `${require("../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Reasons to hire a wedding planner.png")}`,
    author: "trunnkinlove",
    date: "Nov 28, 2023",
    title: "REASONS YOU SHOULD HIRE A WEDDING PLANNER",
    summary:
      "Finally, the day has come. You are the main character, and your beloved is waiting for you at the mandap! But wait – why are some guests queueing outside the venue? Why is the photographer....",
    link: "/reasons-you-should-hire-a-wedding-planner",
  },
];

const Insights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogsData.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(blogsData.length / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  const paginationStyle = {
    display: "flex",
    listStyle: "none",
    justifyContent: "center",
  };

  const pageItemStyle = {
    border: "1px solid #ddd",
    padding: "5px 10px",
    margin: "0 5px",
    cursor: "pointer",
  };

  const activePageItemStyle = {
    ...pageItemStyle,
    borderColor: "#000",
    fontWeight: "bold",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>

        <h2 className="text-white text-[40px] z-0    ">Blogs</h2>
      </div>

      <main className="mt-32">
        <div className="blog-area pb-120">
          <div className="container">
            <div className="lg:px-10">
              <div className="flex flex-wrap gap-10 justify-start">
                {currentBlogs.map((blog, index) => (
                  <div
                    key={index}
                    className={`md:mr-35 lv-blog-space-right-1-8 lg:w-[30%] w-full ${
                      index < 4
                    }`}
                  >
                    <div className="lv-blog-box-1-8 mb-50">
                      <div className="lv-blog-thumb-1-8 -z-[900]">
                        <img src={blog.image} alt="" />
                      </div>
                      <div className="lv-blog-content-1-8 h-[400px]">
                        <div className="lv-blog__meta-1-8 mb-15">
                          <ul>
                            <li>
                              <a className="no-underline" href="#">
                                <i className="far fa-user"></i> {blog.author}{" "}
                              </a>
                              <span className="">{blog.date}</span>
                            </li>
                          </ul>
                        </div>
                        <h4 className="!text-[18px] font-bold">
                          <a
                            className="no-underline text-[#020163]"
                            href={blog.link}
                          >
                            {blog.title}
                          </a>
                        </h4>
                        <p className="mb-25">{blog.summary}</p>
                        <button className="text-white ">
                          <a
                            className=" no-underline hover:text-[#000066] hover:border-2  hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl  py-2 px-10 rounded-md"
                            href={blog.link}
                          >
                            {" "}
                            Read More
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <ul style={paginationStyle}>
                  {currentPage > 1 && (
                    <li
                      style={pageItemStyle}
                      onClick={() => paginate(currentPage - 1)}
                    >
                      &lt;
                    </li>
                  )}
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      style={
                        currentPage === number
                          ? activePageItemStyle
                          : pageItemStyle
                      }
                      onClick={() => paginate(number)}
                    >
                      {number.toString().padStart(2, "0")}
                    </li>
                  ))}
                  {currentPage < pageNumbers.length && (
                    <li
                      style={pageItemStyle}
                      onClick={() => paginate(currentPage + 1)}
                    >
                      &gt;
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Insights;
