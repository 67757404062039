import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Shamika&Manish/Shamika&Manish.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const ShamikaManish = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    portfolio1: require("../../assets/img/portfolio/Shamika&Manish/1.webp"),
    portfolio2: require("../../assets/img/portfolio/Shamika&Manish/2.webp"),
    portfolio3: require("../../assets/img/portfolio/Shamika&Manish/3.webp"),
    portfolio4: require("../../assets/img/portfolio/Shamika&Manish/4.webp"),
    portfolio5: require("../../assets/img/portfolio/Shamika&Manish/5.webp"),
    portfolio6: require("../../assets/img/portfolio/Shamika&Manish/6.webp"),
    portfolio7: require("../../assets/img/portfolio/Shamika&Manish/7.webp"),
    portfolio8: require("../../assets/img/portfolio/Shamika&Manish/8.webp"),
    portfolio9: require("../../assets/img/portfolio/Shamika&Manish/9.webp"),
    portfolio10: require("../../assets/img/portfolio/Shamika&Manish/10.webp"),
    portfolio11: require("../../assets/img/portfolio/Shamika&Manish/11.webp"),
    portfolio12: require("../../assets/img/portfolio/Shamika&Manish/12.webp"),
    portfolio13: require("../../assets/img/portfolio/Shamika&Manish/13.webp"),
    portfolio14: require("../../assets/img/portfolio/Shamika&Manish/14.webp"),
    portfolio15: require("../../assets/img/portfolio/Shamika&Manish/15.webp"),
    portfolio16: require("../../assets/img/portfolio/Shamika&Manish/16.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>

        <h2 className="text-white text-center text-[60px] z-0">
          Shamika & Manish
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row justify-center gap-10 lg:px-10 px-2 py-10">
        <div class="lg:w-[500px]">
          <div class="lv-portfolio-details-top-wrap-1-4 mb-35">
            <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
              Shamika & Manish
            </h4>

            <span class="lv-portfolio-date-1-4">November 2022, Mumbai</span>
          </div>
          <div class="lv-portfolio-details-content-1-4 mb-30">
            <h2>A grand Shetty affair</h2>
            <p class="mb-25">
              Shamika and Manish's wedding, a quintessential Shetty affair in
              Mumbai, was a testament to the timeless allure of tradition and
              love. Amidst the bustling streets of the city, they embarked on a
              journey of marital bliss, surrounded by the warmth of family and
              the vibrant hues of cultural festivities. Their union not only
              marked the blending of two souls but also honored the rich
              heritage that binds them together, making it a truly memorable
              celebration for all who were fortunate to witness it.
            </p>
          </div>
        </div>

        <div className="lg:w-[600px]">
          {/* Mapping other images, assuming similar behavior for simplicity */}
          <div className="py-3">
            <img
              className=""
              src={require("../../assets/img/portfolio/Shamika&Manish/16.webp")}
            />
          </div>
          <div className="flex gap-3 flex-col lg:flex-row ">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio1"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio2"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio3"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio4"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio5"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio6"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio7"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio8"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-no-repeat  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio9"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio10"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio11"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio12"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio13"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio14"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row  py-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio15"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio16"]})`,
              }}
            ></div>
          </div>

          {/* Modal for displaying the clicked image */}
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Modal"
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShamikaManish;
