import logo from "./logo.svg";
import "./App.css";
import Home from "./page/Home/Home";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AboutUs from "./page/About/AboutUs";
import AppRoutes from "./Routes";

function App() {
  return <AppRoutes />;
}

export default App;
