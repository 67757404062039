import React from "react";

import "../../assets/css/main.css";
import { InstagramEmbed } from "react-social-media-embed";

import instagram1 from "../../assets/img/instagram/insta-1.webp";
import instagram2 from "../../assets/img/instagram/insta-2.webp";
import instagram3 from "../../assets/img/instagram/insta-3.webp";
import instagram4 from "../../assets/img/instagram/insta-4.webp";
import instagram5 from "../../assets/img/instagram/insta-5.webp";
import instagram6 from "../../assets/img/instagram/insta-6.webp";
import { FooterSecondRoutes } from "./FooterSecondRoues";
import { FooterThirdRoutes } from "./FooterThirdRoues";
import { FooterForthRoutes } from "./FooterForthRoutes";

const Footer = () => {
  return (
    <div>
      {/* <div
        className="py-[5rem]"
        style={{ display: "flex", justifyContent: "center", gap: "10px" }}
      >
        <div style={{ position: "relative", width: "320px" }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/C5qbK_vLkrU/"
            clientAccessToken="your-app-id|your-client-token"
            // maxWidth={320}

            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
          />
          <div
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              right: 0,
              // Adjust this value based on testing and the size of the comments area
              backgroundColor: "white", // Match the background or make it transparent
            }}
          ></div>
        </div>
        <div style={{ position: "relative", width: "320px" }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/C5uwUIfN6ec/"
            clientAccessToken="your-app-id|your-client-token"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
          />
          <div
            style={{
              position: "absolute",
              bottom: 20,

              left: 0,
              right: 0,
              // Adjust this value based on testing and the size of the comments area
              backgroundColor: "white", // Match the background or make it transparent
            }}
          ></div>
        </div>
        <div style={{ position: "relative", width: "320px" }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/C56F5straKw/"
            clientAccessToken="your-app-id|your-client-token"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
          />
          <div
            style={{
              position: "absolute",
              bottom: 20,

              left: 0,
              right: 0,
              // Adjust this value based on testing and the size of the comments area
              backgroundColor: "white", // Match the background or make it transparent
            }}
          ></div>
        </div>
        <div style={{ position: "relative", width: "320px" }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/C6QDLdutn0_/"
            clientAccessToken="your-app-id|your-client-token"
            maxWidth={320}
            z-index={-90}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
          />
          <div
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              right: 0,
              // Adjust this value based on testing and the size of the comments area
              backgroundColor: "white", // Match the background or make it transparent
            }}
          ></div>
        </div>
      </div> */}
      {/* <div class="instagram-area py-20">
        <div class="container-fluid pl-20 pr-20">
          <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg1}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg2}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg3}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg4}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg5}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-30 mb-xl-0">
              <div
                class="lv-instagram-img lv-instagram-img-height bg-default"
                style={instagrambg6}
              >
                <div class="lv-instagram-icon">
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- footer area start --> */}
      <div className=" bg-[#111111]  !text-white  font-semibold mt-4  ">
        {/* <div className="border-b-2 border-white py-6 ">
        <ul className="  flex items-center flex-wrap md:justify-center gap-x-56 md:gap-20 px-4  ">
          {FooterTopRoutes.map((route) => {
            const { href, title } = route;
            return (
              <li className="group relative">
                <a href={href} className="flex items-center">
                  {title}
                </a>
                <span className="absolute bottom-0 left-0 w-0 bg-[#A2272E] h-0.5 group-hover:w-full transition-all duration-300"></span>
              </li>
            );
          })}
        </ul>
      </div> */}
        <div className="flex flex-wrap justify-between items-start md:px-10 px-5 py-6">
          <div className="md:w-1/4 w-full ">
            <h4 className="text-lg uppercase !pb-10 !text-white">
              HEADQUARTER
            </h4>
            <img
              className="w-16"
              src={require("../../assets/img/logo/contacticon.png")}
            />
            <p className="py-6 text-justify">
              <i className="fa-solid fa-location-dot  "></i> 304 B wing, Kotia
              Nirman, New link road, Near Fun republic, Andheri west, Mumbai -
              400 058.
            </p>
          </div>
          <div className="md:w-1/4 w-full md:px-12">
            <h4 className="text-lg uppercase !text-white  ">REACH US</h4>
            <ul className="pt-6 ">
              {FooterSecondRoutes.map((route) => {
                const { href, title, icon } = route;
                return (
                  <li className="py-1 group relative ">
                    <a href={href} className="flex gap-2 items-center">
                      {icon}
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="md:w-1/4 w-full">
            <h4 className="text-lg uppercase !text-white">QUICK LINKS</h4>
            <ul className="pt-6">
              {FooterThirdRoutes.map((route) => {
                const { href, title, icon } = route;
                return (
                  <li className="py-1 group relative">
                    <a href={href} className="flex gap-2 items-center">
                      {icon}
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="md:w-1/4 w-full">
            <h4 className="text-lg uppercase ">Get In Touch</h4>
            <ul className="pt-6">
              {FooterForthRoutes.map((route) => {
                const { href, title, icon, subtitle, image } = route;
                return (
                  <li className=" flex gap-2 group relative">
                    <a href={href} className="pt-2 ">
                      {icon} {title} <br />
                      {subtitle} {image}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div> */}
        </div>
        <div className="bg-[#000000] border-t-2 py-4 border-white md:px-10 px-5 text-sm">
          <p>© 2024 Trunnk In Love. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
