import React from "react";
import HeroImage from "../../../assets/img/slider/blogbanner.jpg";
import Author from "../../../assets/img/portfolio/s6.webp";

const DetailsPage4 = () => {
  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };
  const bhAuthor = {
    backgroundImage: `url(${Author})`,
  };
  return (
    <div>
      {" "}
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-[60px] text-center z-0">
          REASONS YOU SHOULD HIRE A WEDDING PLANNER
        </h2>
      </div>
      <main className="mt-36">
        {/* <!-- blog area start  --> */}
        <div class="blog-area pb-110">
          <div class="container lg:w-[995px]">
            <div class="row">
              <div class="">
                <div class="md:mr-35 lv-blog-space-right-1-8">
                  <div class="lv-blog-box-1-8 mb-50">
                    <div class="lv-blog-thumb-1-8">
                      <img
                        src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Reasons to hire a wedding planner.png")}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div class="lv-blog-content-1-8">
                      <div class="lv-blog__meta-1-8 mb-15">
                        <ul>
                          <li>
                            <a href="">
                              <i class="far fa-user"></i> trunnkinlove Nov 28,
                              2023
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="lv-blog-content-text-wrap-1-8">
                        <h4 class="lv-blog-title-1-8">
                          REASONS YOU SHOULD HIRE A WEDDING PLANNER
                        </h4>

                        <p class="mb-25">
                          {" "}
                          Finally, the day has come. You are the main character,
                          and your beloved is waiting for you at the mandap! But
                          wait – why are some guests queueing outside the venue?
                          Why is the photographer lazing around in the corner?
                          And why are your parents racing around in distress?
                          Well, these are some scenarios you can easily avoid by
                          hiring a wedding planner. Aprofessional wedding
                          planner can be your lifesaver and bring your dream
                          wedding to life. From ensuring every detail is
                          flawlessly executed to relieving you of unnecessary
                          stress, a wedding planner will help you navigate
                          through vendor selections, budget management, and
                          timeline coordination. All this, so you can focus on
                          celebrating your love! So, before you decide on your
                          wedding playlist, here are nine reasons to hire a
                          wedding planner.
                        </p>
                        {/* <blockquote>
                          <p>
                            Tosser argy-bargy mush loo at public school
                            Elizabeth up the duff buggered chinwag on your bike
                            mate don’t get shirty with me super, Jeffrey bobby
                            Richard cheesed off spend a penny a load of old tosh
                            blag horse.
                          </p>
                          <p>
                            <cite>Justin Bieber</cite>
                          </p>
                        </blockquote> */}
                        <h4 class="lv-blog-title-1-8">
                          9 Reasons You Should Hire A Wedding Planner
                        </h4>
                        <h5 class="">
                          1. Consider Your Specific Requirements And Preferences
                        </h5>
                        <p class="mb-30">
                          Generally, there are five types of wedding planners –
                          event designers, month-of coordinators, part-time,
                          full-time, and destination wedding planners. As such,
                          full-time or destination planners handle everything
                          from A to Z, including guest coordination, vendor
                          hiring, etc. This option suits multi-day or
                          destination weddings. For occasional assistance, you
                          can opt for a part-time planner who can help with
                          specific details or provide suggestions for caterers
                          and rentals. So, these types of planners are suitable
                          for lovebirds on a budget! Finally, a month-of
                          coordinator focuses on the wedding day, ensuring
                          smooth operation and execution. They may begin working
                          with you a few weeks prior to the wedding day to
                          handle setup, schedule management, and post-wedding
                          tasks like tipping.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Consider Your Specific Requirements And Preferences.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">2. Caters To Your Budget</h5>
                        <p class="mb-30">
                          A good wedding planner knows how to work within
                          budgets. They possess industry expertise and can
                          secure exclusive deals while prioritising your budget,
                          sparing you the hassle. Further, they help you
                          allocate funds wisely, negotiate with vendors, find
                          cost-effective alternatives, and ensure you get the
                          most out of your money.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Caters To Your Budget.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">3. Find the Best Vendors</h5>
                        <p class="mb-30">
                          Hiring a wedding planner can help you connect with the
                          best wedding vendors. For instance, in a dating app,
                          you find compatible matches by going through different
                          profiles. But no matter how hard you try, bad dates
                          are bound to happen! Similarly, you will come across a
                          few “bad” vendors if you start searching by yourself.
                          However, wedding planners have the experience and
                          insight to pick the cream of the crop. They will help
                          save you time and effort by finding vendors that align
                          with your style and budget.
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Find the Best Vendors.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">4. Helps Balance Everyone’s Demands</h5>
                        <p class="mb-30">
                          Every important moment in life comes with advice
                          (often unsolicited) from near and dear ones. That’s
                          when you need a wedding planner to cut through the
                          noise. With numerous stakeholders involved, including
                          the couple, parents, friends, and more, having a
                          professional presence in the room can make a
                          difference. After all, advice carries more weight when
                          it’s from a professional. Hence, a wedding planner is
                          crucial in navigating through the complex
                          decision-making process.
                        </p>

                        <h5 class="">
                          5. Introduces You To Important Wedding Etiquette
                        </h5>
                        <p class="mb-30">
                          Regardless of how unique or unconventional your
                          wedding is going to be, there are essential wedding
                          rules that you should know. Wedding planners are
                          well-versed in these protocols, like the proper
                          placement of details on invitations and more. In fact,
                          they excel in handling situations that may have never
                          crossed your mind!
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Introduces You To Important Wedding Etiquette.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>

                        <h5 class="">6. Offer Unmatched Expertise</h5>
                        <p class="mb-30">
                          One of the most important reasons you should hire a
                          wedding planner is their extensive knowledge and
                          expertise. Just like a financial advisor helps you
                          manage your funds wisely, a wedding planner swiftly
                          tackles challenges that could consume hours of your
                          time. Serving as a coordinator, budget manager, legal
                          advisor, and designer, a wedding planner can save you
                          both time and money while bringing your wedding vision
                          to life. This makes wedding planners an invaluable
                          asset for busy couples seeking a stress-free approach
                          to their weddings.
                        </p>

                        <h5 class="">7. Executes Your Wedding Vision</h5>
                        <p class="mb-30">
                          Hiring a wedding planner is a worthwhile investment
                          because they have the ability to turn your dream
                          wedding into a reality. And the fact that you can
                          choose one after meeting multiple people is the cherry
                          on the cake. Sounds like online dating again! But
                          jokes apart, you can always take your time to figure
                          out if a wedding planner is right for you. We
                          recommend preparing a few questions and attentively
                          listening to their responses. Whether you envision a
                          serene beach wedding or an extravagant ceremony, the
                          right planner will possess knowledge of the finest
                          venues and vendors to execute your fairytale wedding!
                        </p>
                        <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/9 Reasons You Should Hire A Wedding Planner/Executes Your Wedding Vision.png")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p>
                        <h5 class="">
                          8. They Will Ensure Your Guests Are Comfortable
                        </h5>
                        <p class="mb-30">
                          Although the couple is the star of the show, your
                          guests are just as important on your wedding day. So,
                          ensuring their comfort and enjoyment is the top
                          priority. This includes considering the needs of all
                          attendees, from the elderly to the little ones, with
                          the goal of ensuring they have a memorable time. As
                          such, your wedding planner recognises the significance
                          of each guest and strives to make everyone feel
                          special and well taken care of. This can include
                          arranging gifts for each guest, entertainment areas
                          for children, and more.
                        </p>
                        {/* <p class="mb-25">
                          <img
                            src={require("../../../assets/img/blog/blog-detailspage4.4.jpg")}
                            alt=""
                            className="w-[600px] m-auto"
                          />
                        </p> */}
                        <h5 class="">9. Helps You Enjoy Your Special Day</h5>
                        <p class="mb-30">
                          If you need only one reason why you should hire a
                          wedding planner, this is it! On your wedding day, you
                          obviously want to be stress-free and enjoy every
                          moment. That’s when professional wedding planning
                          works as a safety net, ready to handle any unforeseen
                          issues on your big day. Planning a wedding with a pro
                          means spending valuable time with your friends and
                          family on the wedding day instead of stressing over
                          the missing cake… or the groom’s shoes!
                        </p>
                        <h4 class="">Final Words</h4>
                        <p class="mb-30">
                          Wedding planning is already a Herculean task, let
                          alone organising a big fat Indian wedding! Hence, to
                          save you from the hassle and last-minute stress, we
                          recommend hiring a wedding planner. Choose one who can
                          accompany you through the ins and outs of budget
                          management, vendor navigation, etc. Regardless of your
                          wedding menu, a professional planner is the secret
                          ingredient to a stress-free and unforgettable wedding
                          experience!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xxl-4 col-lg-4 col-lg-4">
                <div class="lv-blog-sidebar-1-8">
                  <div class="widget lv-widget widget-blog-author mb-50">
                    <div
                      class="lv-blog-author-widget-img-6 bg-default mb-40"
                      style={bhAuthor}
                    ></div>
                    <div class="lv-author-widget-content-6">
                      <h4 class="lv-widget-heading-1-6 mb-10">ABOUT AUTHOR</h4>
                      <p>
                        jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty. Itaque earum
                        rerum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- blog area end  -->
            <!-- instagram area start --> */}

        {/* <!-- instagram area end --> */}
      </main>
    </div>
  );
};

export default DetailsPage4;
